import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';
import locales from '../resources/i18n';
import { Cookies } from 'react-cookie';

const lang = new Cookies().get('i18lang');

i18n.use(reactI18nextModule).init({
  fallbackLng: 'en',
  resources: locales,
  lng: lang,
  defaultNS: 'global',
  react: {
    wait: true
  }
});

export default i18n;