import styled from "styled-components";

export const Selector = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SelectorButton = styled.button`
  font-weight: 300;
  line-height: normal;
  font-size: 0.875rem;
  padding: 0.5em 2em;
  text-align: center;
  border: 1px solid #5563E1;
  transition: all 0.25s ease-in-out;
  background: ${props => props.active ? '#5563E1' : 'transparent'};
  color: ${props => props.active ? '#FFFFFF' : '#5563E1'};
  &:first-of-type {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }
  &:last-of-type {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }
  &:not(:last-of-type) {
    border-right-width: 0;
  }
`;