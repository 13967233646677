import * as React from "react";
import styled from "styled-components";

const AvatarBase = styled.div`
  position: relative;
  background: ${props => props.img ? `url("${props.img}") no-repeat` : "#c4c4c4"};
  background-size: contain;
  border: 3px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  width: 130px;
  height: 130px;
  margin: 1.5rem auto 0.5rem !important;
  & input {
    display: none;
  }
  & label {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    cursor: pointer;
    & i {
      font-size: 3rem;
    }
  }
`;

export const AvatarLoader = ({id, change, image}) => {
  return(
    <AvatarBase img={image}>
      <label htmlFor={id}>
        {!image ? <i className="material-icons">cloud_upload</i> : null}
      </label>
      <input type="file" id={id} onChange={change} accept="image/*"/>
    </AvatarBase>
  )
};
