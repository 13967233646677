import * as React from "react";
import styled, { keyframes } from "styled-components";

const lockerLoadingAnimation = keyframes`
  0%, 50%, 100% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
`;

const cellOpenAnimation = keyframes`
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  60% {
    transform: skewY(0) translateY(0) scaleX(1) translateX(0);
  }
  100% {
    transform: skewY(-25deg) translateY(-4px) scaleX(0.85) translateX(-1px);
  }
`;

const LockerLoaderBase = styled.div`
  display: inline-block;
  position: fixed;
  z-index: 99999;
  left: calc(50% - 40px);
  top: calc(50% - 32px);
  width: 80px;
  height: 64px;
  & div {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    background: #f05a28;
    animation: ${lockerLoadingAnimation} 3s infinite ease-in-out;
    &:nth-child(1) {
      top: 6px;
      left: 6px;
      animation-delay: 1s;
    }
    &:nth-child(2) {
      top: 6px;
      left: 30px;
      animation: ${cellOpenAnimation} 3s infinite ease-in-out;
      animation-delay: 0.5s;
    }
    &:nth-child(3) {
      top: 6px;
      left: 54px;
    }
    &:nth-child(4) {
      top: 30px;
      left: 30px;
      animation-delay: 1.5s;
    }
  }
`;

const DotLoaderAnimation1 = keyframes`
  0% {
    transform: scale(0);
}
  100% {
    transform: scale(1);
}`;
const DotLoaderAnimation3 = keyframes`
  0% {
    transform: scale(1);
}
  100% {
    transform: scale(0);
}`;
const DotLoaderAnimation2 = keyframes`
  0% {
    transform: translate(0, 0);
}
  100% {
    transform: translate(19px, 0);
}`;

const DotLoaderBase = styled.div`
  display: inline-block;
  position: relative;
  width: 64px;
  height: 1rem;
  & div {
    position: absolute;
    top: calc(50% - 0.25rem);
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background: #f05a28;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    &:nth-child(1) {
      left: 6px;
      animation: ${DotLoaderAnimation1} 0.6s infinite;
    }
    &:nth-child(2) {
      left: 6px;
      animation: ${DotLoaderAnimation2} 0.6s infinite;
    }
    &:nth-child(3) {
      left: 26px;
      animation: ${DotLoaderAnimation2} 0.6s infinite;
    }
    &:nth-child(4) {
      left: 45px;
      animation: ${DotLoaderAnimation3} 0.6s infinite;
    }
  }
`;

export const Loader = () => {
  return (
    <LockerLoaderBase>
      <div />
      <div />
      <div />
      <div />
    </LockerLoaderBase>
  );
};

export const DotLoader = () => {
  return (
    <DotLoaderBase>
      <div />
      <div />
      <div />
      <div />
    </DotLoaderBase>
  );
};
