import * as React from "react";
import { withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import styled from "styled-components";
import logo from "../../resources/img/logo_white.svg";
import person from "../../resources/img/icons/person.svg";
import ProfileSettings from "../../pages/profileSettings";
import NumberFormat from "react-number-format";
import { phoneFormats } from "../../resources/constants/phone-formats";
import { logout } from "../../services/authorization.service";

const Navigation = styled.div`
  position: ${props => props.position || "fixed"};
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  padding: 1em 2.75em;
  background: #f05a28;
  color: #ffffff;
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.25);
  z-index: 99;
`;

const Logo = styled.div`
  width: 45px;
  height: 35px;
  background: url(${logo}) no-repeat center center;
  background-size: contain;
  @media (max-width: 768px) {
    display: ${props => props.show ? 'block' : 'none'};
  }
`;

const UserAvatar = styled.div`
  width: 2rem;
  height: 2rem;
  border: 2px solid #FFFFFF;
  border-radius: 50%;
  background: #F4F5FA url("${props => props.avatar || person}") no-repeat;
  background-size: contain;
  margin-left: 1rem;
`;
const UserMenuItem = styled.button`
  background: transparent;
  color: #fff;
  border: 0;
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  padding: 1.125rem 2rem;
  font-size: 0.875rem;
  &:not(:first-of-type) {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    cursor: pointer;
  }
  > .material-icons {
    font-size: 2rem;
    margin-left: 1rem;
  }
`;
const UserMenu = styled.div`
  background: transparent;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 250px;
  overflow: hidden;
  max-height: 70px;
  &:hover {
    max-height: none;
    background: #ed8d6d;
    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.25);
  }
`;

const MenuBtn = styled.button`
  border: 2px solid #ffffff;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  padding: 0;
  cursor: pointer;
  @media (min-width: 769px) {
    display: none;
  }
`;

const Burger = styled.div`
  width: 1.5rem;
  height: 2px;
  background: #ffffff;
  &:not(:first-of-type) {
    margin-top: 4px;
  }
`;

const MenuButton = () => {
  return (
    <MenuBtn
      onClick={() =>
        document.getElementById("content").classList.toggle("showSidebar")
      }
    >
      <Burger />
      <Burger />
      <Burger />
    </MenuBtn>
  );
};

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false
    };
  }

  render() {
    const { t, currentUser, showMenu } = this.props;
    return (
      <Navigation position={this.props.position}>
        <Logo show={!showMenu}/>
        {showMenu ? (
          <React.Fragment>
            <MenuButton />
            {currentUser ? (
              <UserMenu>
                <UserMenuItem>
                  <NumberFormat
                    value={currentUser.phone}
                    displayType={"text"}
                    format={phoneFormats[currentUser.phone.length.toString()]}
                  />
                  <UserAvatar avatar={""} />
                </UserMenuItem>
                <UserMenuItem
                  onClick={() => this.setState({ modalShow: true })}
                >
                  {t("profileSettings")}
                  <i className="material-icons">settings</i>
                </UserMenuItem>
                <UserMenuItem onClick={logout}>
                  {t("signOut")}
                  <i className="material-icons">exit_to_app</i>
                </UserMenuItem>
              </UserMenu>
            ) : null}
            {this.state.modalShow ? (
              <ProfileSettings
                close={() => this.setState({ modalShow: false })}
              />
            ) : null}
          </React.Fragment>
        ) : null}
      </Navigation>
    );
  }
}

const mapStateToProps = state => ({ currentUser: state.currentUser });

export default withRouter(
  withCookies(withNamespaces("navbar")(connect(mapStateToProps)(Navbar)))
);
