import * as React from "react";
import styled from "styled-components";
import { Rating } from "./ratingStar";
import { Flex, FlexColumn } from "./flexboxes";
import moment from "moment";
import i18n from "../config/i18n";
import { SmallGray } from "./fonts";

const ReviewTitle = styled.span`
  font-size: 1rem;
`;

const ReviewText = styled.span`
  font-size: 0.875rem;
  color: #67676e;
  font-weight: ${props => (props.light ? 300 : props.bold ? 500 : 400)};
`;

const ReviewDate = styled.span`
  font-size: 0.75rem;
  color: #92929c;
`;

const WriteReviewBase = styled.form`
  position: relative;
  display: flex;
`;

const TextArea = styled.textarea`
  background: transparent;
  font-size: 0.875rem;
  color: #67676e;
  padding: 0.5em;
  width: 100%;
  border: 1px solid #f05a28;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  &:focus {
    outline: 0;
  }
`;

const Send = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  border: 1px solid #f05a28;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #f05a28;
  color: #fff;
`;

export const Review = ({ type, item }) => {
  return (
    <FlexColumn marginBottom="0.5rem">
      <Flex alignItems="baseline" innerSpace="0.25rem">
        {type === "locker" ?
          <ReviewTitle>
            {item.type === "CLIENT"
              ? item.client.fullname
              : `${item.user.firstname} (${i18n.t(
                `staff:roles.${item.user.role}`
              )})`}
          </ReviewTitle>
        : null}
        <Rating rating={item.rating} />
      </Flex>
      {type === "locker" && item.type === "CLIENT" ? (
        <Flex alignItems="baseline" innerSpace="0.25rem">
          <ReviewText>{i18n.t("global:orderId")}</ReviewText>
          <ReviewText light>{item.order.identificator}</ReviewText>
        </Flex>
      ) : null}
      {item.comment ? (
        <Flex alignItems="baseline" innerSpace="0.25rem">
          <ReviewText>{i18n.t("global:comment")}</ReviewText>
          <ReviewText light>{item.comment}</ReviewText>
        </Flex>
      ) : null}
      <ReviewDate>
        {moment(item.createDate).format("DD.MM.YY - HH:mm")}
      </ReviewDate>
    </FlexColumn>
  );
};

export const WriteReview = ({ rating, setRating, value, change, send }) => {
  return (
    <FlexColumn margin="1em 0">
      <Flex alignItems="baseline" innerSpace="0.25rem">
        <SmallGray>{i18n.t("lockerInfo:rating")}</SmallGray>
        <Rating rating={rating} change={e => setRating(e)} />
      </Flex>
      <SmallGray>{i18n.t("lockerInfo:comment")}</SmallGray>
      <WriteReviewBase>
        <TextArea value={value} onChange={change} rows="3" />
        <Send type="button" onClick={send}>
          <i className="material-icons">send</i>
        </Send>
      </WriteReviewBase>
    </FlexColumn>
  );
};
