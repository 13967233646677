import * as React from "react";
import {withNamespaces} from "react-i18next";
import Modal from "../../components/modal";
import {BasicInput, BasicSelect, BasicPhoneInput} from "../../components/basicInput";
import {FlexRow} from "../../components/flexboxes";
import {ActionButton} from "../../components/button";
import {bookFFCell, getFFLockers} from "../../services/tracking.service";
import {handleError} from "../../services/other.service";
import { phoneFormats } from "../../resources/constants/phone-formats";

class BookFFCell extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            identificator: '',
            locker: '',
            size: '',
            recipientPhone: '+ 7',
            recipientName: '',
            lockers: [],
            input: []
        };
        this.getFFLockers();
    }

    book = e => {
        e.preventDefault();

        let order = {
            identificator: this.state.identificator,
            locker: this.state.locker,
            size: this.state.size,
            recipientPhone: this.state.recipientPhone.replace(/[^a-zA-Z0-9 ]/g, ""),
            recipientName: this.state.recipientName,
        }

        bookFFCell(order)
            .then(() => this.setState({identifier: ''}, () => this.props.close(true, true)))
            .catch(err => this.setState({}, () => handleError(err)));
    }

    handleChange = ({ target: { name, value } }) => {
        if (name === "index") {
            this.state.lockers.forEach(el => {
                if (el.lockerIndex === value) {
                    this.setState({identificator: el.trackNumberTemplate});
                    this.setState({locker: el.lockerPresenceCode});
                    this.setState({size: el.boxSize});
                }
            })
        }
        this.setState({
             [name]: name === "recipientPhone"
             ? value.substring(1, value.length).replace(/ +/g, "") //remove prefix and spaces between digits
             : value
        })
    }

    getFFLockers = e => {
        getFFLockers()
            .then(res => {
                this.setState({lockers: res.data.list})
                let tmp = [];
                this.state.lockers.forEach(el => {
                    tmp.push({
                        label: `${el.lockerIndex} - ${el.lockerAddress}`,
                        value: el.lockerIndex
                    })
                })
                this.setState({input: tmp})
            })
            .catch(err => this.setState({}, () => handleError(err)));
    }

    render() {
        const {t, close} = this.props;
        const {index, recipientPhone, recipientName, input} = this.state;
        return (
            <Modal
                id="bookFFCell"
                size="small"
                title={t("title")}
                closeModal={() => close(false, false)}
            >
                <form onSubmit={this.book}>
                    <FlexRow marginBottom="1rem" fullWidth>
                        <BasicSelect
                            label={t("index")}
                            name="index"
                            value={index}
                            options={input}
                            onChange={this.handleChange}
                            required
                        />
                    </FlexRow>
                    <FlexRow marginBottom="1rem" fullWidth>
                        <BasicInput
                            label={t("fullname")}
                            name="recipientName"
                            value={recipientName}
                            type="text"
                            onChange={this.handleChange}
                            required
                        />
                    </FlexRow>
                    <FlexRow marginBottom="1rem" fullWidth>
                        <BasicPhoneInput
                            label={t("phone")}
                            icon="phone_iphone"
                            name="recipientPhone"
                            value={recipientPhone}
                            onChange={this.handleChange}
                            format={phoneFormats["11"]}
                            required
                        />
                    </FlexRow>
                    <ActionButton
                        type="submit"
                        title={t("book")}
                        size="small"
                        click={this.book}
                        disabled={!index || !recipientName || !recipientPhone}
                    />
                </form>
            </Modal>
        );
    }
}

export default withNamespaces("bookFFCell")(BookFFCell);
