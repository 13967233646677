import * as React from "react";
import MainLayout from "../layouts/main";
import { withNamespaces } from "react-i18next";
import moment from "moment";
import { Title } from "../components/title.js";
import { Table, Tr, Td } from "../components/flexibleTable.js";
import { Row } from "../components/flexboxes";
import Application from "./modals/application";
import {
  getAllApplications,
  getApplication
} from "../services/applications.service";
import { handleError } from "../services/other.service";
import { Action } from "./staff";
import { Th } from "../components/flexibleTable";

class Requests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      applications: {},
      chosenApp: {},
      modalShow: false
    };
  }

  componentDidMount() {
    this.getList();
  }

  getList = () => {
    getAllApplications()
      .then(res => this.setState({ applications: res.data.list }))
      .catch(err => handleError(err));
  };

  chooseApplication = id => {
    getApplication(id)
      .then(res => this.setState({ chosenApp: res.data, modalShow: true }))
      .catch(err => handleError(err));
  };

  render() {
    const { t } = this.props;
    const { applications, chosenApp, modalShow } = this.state;
    const tableHeaders = [
      t("createDate"),
      t("name"),
      t("status"),
      t("actions")
    ];
    return (
      <React.Fragment>
        <Row justifyContent="space-between" marginBottom="0.5rem" fullWidth>
          <Title>{t("title")}</Title>
        </Row>
        <Table
          fullWidth
          headers={tableHeaders.map((h, index) => (
            <Th key={index}>{h}</Th>
          ))}
        >
          {applications.length > 0 ? (
            applications.map((a, index) => (
              <Tr key={index}>
                <Td>{moment(a.createDate).format("DD.MM.YY - HH:mm")}</Td>
                <Td>{a.name}</Td>
                <Td>{t(`statuses.${a.status}`)}</Td>
                <Td>
                  <Action onClick={() => this.chooseApplication(a.id)}>
                    {t("see")}
                  </Action>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={4}>{t("global:nothingFound")}</Td>
            </Tr>
          )}
        </Table>
        {modalShow ? (
          <Application
            application={chosenApp}
            title={t("application")}
            getList={this.getList}
            close={() =>
              this.setState(
                {
                  modalShow: false
                },
                () => this.getList()
              )
            }
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default withNamespaces("applications")(MainLayout(Requests));
