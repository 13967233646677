export const cities = [
  {
    name: {
      ru: 'Астана',
      kk: 'Астана',
      en: 'Astana'
    },
    code: '01',
    location: {
      lat: 51.129922,
      lng: 71.438145
    }
  },
  {
    name: {
      ru: 'Алматы',
      kk: 'Алматы',
      en: 'Almaty'
    },
    code: '02',
    location: {
      lat: 43.238293,
      lng: 76.945465
    }
  },
  {
    name: {
      ru: 'Караганда',
      kk: 'Қарағанды',
      en: 'Qaragandy'
    },
    code: '09',
    location: {
      lat: 49.807754,
      lng: 73.088504
    }
  },
  {
    name: {
      ru: 'Темиртау',
      kk: 'Темиртау',
      en: 'Temirtau'
    },
    code: '091',
    location: {
      lat: 50.058469,
      lng: 72.975473
    }
  },
  {
    name: {
      ru: 'Павлодар',
      kk: 'Павлодар',
      en: 'Pavlodar'
    },
    code: '14',
    location: {
      lat: 52.285577,
      lng: 76.940947
    }
  },
  {
    name: {
      ru: 'Шымкент',
      kk: 'Шымкент',
      en: 'Shymkent'
    },
    code: '17',
    location: {
      lat: 42.315514,
      lng: 69.586907
    }
  },
  {
    name: {
      ru: 'Усть-Каменогорск',
      kk: 'Өскемен',
      en: 'Óskemen'
    },
    code: '16',
    location: {
      lat: 49.948759,
      lng: 82.628459
    }
  },
  {
    name: {
      ru: 'Актобе',
      kk: 'Ақтөбе',
      en: 'Aktobe'
    },
    code: '04',
    location: {
      lat: 50.300371,
      lng: 57.154555
    }
  },
  {
    name: {
      ru: 'Петропавловск',
      kk: 'Петропавл',
      en: 'Petropavlovsk'
    },
    code: '15',
    location: {
      lat: 54.867254,
      lng: 69.137319
    }
  },
  {
    name: {
      ru: 'Актау',
      kk: 'Ақтау',
      en: 'Aktau'
    },
    code: '12',
    location: {
      lat: 43.635601,
      lng: 51.168254
    }
  },
  {
    name: {
      ru: 'Кокшетау',
      kk: 'Көкшетау',
      en: 'Kokshetau'
    },
    code: '03',
    location: {
      lat: 53.285140,
      lng: 69.375509
    }
  },
  {
    name: {
      ru: 'Тараз',
      kk: 'Тараз',
      en: 'Taraz'
    },
    code: '08',
    location: {
      lat: 42.897794,
      lng: 71.401002
    }
  }
];
