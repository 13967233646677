import * as React from "react";
import { withNamespaces } from "react-i18next";
import { Options, Select, Value } from "./customSelect";


class CustomSelect2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
  }

  render() {
    const { value, placeholder, children } = this.props;
    const { show } = this.state;
    return (
      <Select
        tabIndex={0}
        active={show}
        onBlur={() => setTimeout(() => this.setState({ show: false }), 150)}
      >
        <Value
          onClick={() =>
            this.setState(prevState => ({ show: !prevState.show }))
          }
        >
          {value ? value : placeholder}
        </Value>
        <Options>
          {children}
        </Options>
      </Select>
    );
  }
}
export default withNamespaces("statuses")(CustomSelect2);