import * as React from "react";
import { withNamespaces } from "react-i18next";
import MainLayout from "../layouts/main";
import styled from "styled-components";
import { Title } from "../components/title";
import { FAQ_QUESTIONS, FAQ_ANSWERS } from "../resources/constants/FAQ_temp";
import { ContentCard } from "../components/contentCard";
import {
  Answer,
  Answers,
  FaqRow,
  Question,
  Questions
} from "../components/faq";

const FaqTemplate = styled(ContentCard)`
  & h3 {
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: normal;
    color: #67676e;
    margin: 0 0 1rem;
  }
  & p {
    font-weight: 300;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #67676e;
    margin: 0.75rem 0;
    & b {
      font-weight: 500;
    }
  }
  & code {
    display: block;
    box-sizing: border-box;
    position: relative;
    background: #f4f4f4;
    padding: 0.5rem 0.75rem 0.5rem 3rem;
    font-weight: 300;
    font-size: 0.75rem;
    color: #67676e;
    width: 100%;
    &:before {
      content: attr(aria-label);
      position: absolute;
      top: 0;
      left: 0;
      width: 2.5rem;
      height: 100%;
      box-sizing: border-box;
      padding: 0.5rem 0.75rem;
      background: #dfdddd;
      font-weight: 300;
      font-size: 0.75rem;
      color: #000000;
    }
  }
`;

class FAQ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: "intro"
    };
  }

  render() {
    const { t } = this.props;
    const { key } = this.state;
    return (
      <React.Fragment>
        <Title marginBottom="1.4rem">{t("title")}</Title>
        <FaqTemplate>
          <FaqRow>
            <Questions width="25%">
              {FAQ_QUESTIONS["en"].map(q =>
                q.child ? (
                  <React.Fragment>
                    <Question>{q.title}</Question>
                    {q.child.map(c => (
                      <Question
                        key={c.key}
                        active={key === c.key}
                        onClick={() => this.setState({ key: c.key })}
                        child
                      >
                        {c.title}
                      </Question>
                    ))}
                  </React.Fragment>
                ) : (
                  <Question
                    key={q.key}
                    active={key === q.key}
                    onClick={() => this.setState({ key: q.key })}
                  >
                    {q.title}
                  </Question>
                )
              )}
            </Questions>
            <Answers width="75%">
              {FAQ_ANSWERS["en"]
                .filter(a => a.key === key)
                .map(a => (
                  <Answer
                    key={a.key}
                    dangerouslySetInnerHTML={{ __html: a.html }}
                  />
                ))}
            </Answers>
          </FaqRow>
        </FaqTemplate>
      </React.Fragment>
    );
  }
}

export default withNamespaces("FAQ")(MainLayout(FAQ));
