import * as React from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import moment from "moment";
import { ActionButton, Close } from "./button";
import {
  blockBox,
  openBox,
  createLoan,
  removeLoan,
  editLoan,
  createOrder,
  openOrderBox,
  unblockBox
} from "../services/boxes.service";
import { handleError, notify, questionAlert } from "../services/other.service";
import { getSystems } from "../services/other.service";
import { FlexColumn, FlexRow } from "./flexboxes";
import CustomSelect2 from "./select";
import SortByPeriod from "./sortByPeriod";
import { LabelWithBorder } from "./labels";
import { Option } from "./customSelect";
import { BasicInput, Label } from "./basicInput";
import { Radio } from "./radioButton";
import { cellSizes } from "../resources/constants/cell-sizes";
import { ModalItem, Text, Title } from "./modal";
import { transferOrder } from "../services/tracking.service";

const initialState = {
  from: '',
  to: '',
  canEditLoan: false,
  canAddLoan: false,
  canAddOrder: false,
  canChangeCell: false,
  systemId: '',
  bookSystemId: '',
  identificator: '',
  size: ''
};

class CellInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState }
  }

  componentDidMount() {
    this.setFromTo();
    getSystems();
  }

  componentWillReceiveProps(nextProps, nextContent) {
    this.setFromTo();
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value })
  }

  setFromTo = () => {
    this.setState({ ...initialState }, () => {
      if (this.props.box.from && this.props.box.to)
        this.sortOrdersByPeriod(this.props.box.from, this.props.box.to);
      else this.sortOrdersByPeriod();
    });
  };

  sortOrdersByPeriod = (from, to) => {
    this.setState({
      from: from ? new Date(from).getTime() : null,
      to: to ? new Date(to).getTime() : null,
      systemId: this.props.box.system ? this.props.box.system.id : null
    });
  };

  block = () => {
    const { locker, box } = this.props;
    questionAlert(this.props.t("toBlock"), () =>
      blockBox(locker.id, box.id)
        .then(() => this.props.refresh())
        .catch(err => handleError(err))
    );
  };

  unblock = () => {
    const { locker, box } = this.props;
    questionAlert(this.props.t("toUnBlock"), () =>
      unblockBox(locker.id, box.id)
        .then(() => this.props.refresh())
        .catch(err => handleError(err))
    );
  };

  open = () => {
    const { locker, box } = this.props;
    questionAlert(this.props.t("toOpen"), () =>
      openBox(locker.code, box.id)
        .then(() => this.props.refresh())
        .catch(err => handleError(err))
    );
  };

  openRemotely = () => {
    const { locker, box } = this.props;
    questionAlert(this.props.t("toOpenRemotely"), () =>
      openOrderBox(locker.code, box.order.pickCode)
        .then(() => this.props.refresh())
        .catch(err => handleError(err))
    );
  };

  create = () => {
    const { from, to, systemId } = this.state;
    const { box, refreshBox } = this.props;
    createLoan(this.props.box.id, systemId, from, to)
      .then(() => refreshBox(box.id))
      .catch(err => handleError(err));
  };

  addOrder = () => {
    const { identificator, bookSystemId } = this.state;
    const { box, refreshBox } = this.props;

    createOrder(box.id, bookSystemId, identificator)
      .then(() => {
        refreshBox(box.id);
        this.clearFields()
      })
      .catch(err => handleError(err));
  };

  edit = () => {
    const { from, to, systemId } = this.state;
    const { box, refreshBox } = this.props;
    editLoan(box.id, systemId, from, to)
      .then(() => refreshBox(box.id))
      .catch(err => handleError(err));
  };

  remove = () => {
    const { t, box, refreshBox } = this.props;
    questionAlert(t("toRemoveLoan"), () =>
      removeLoan(box.id)
        .then(() => refreshBox(box.id))
        .catch(err => handleError(err))
    );
  }

  getOrderInfo = order => {
    const { t } = this.props
    const keys = ['identificator', 'trackNumber', 'status', 'bookDate', 'sentDate']

    return Object.keys(order)
      .filter(key => keys.includes(key) && !!order[key])
      .map(key => (
        <ModalItem key={key}>
          <Title>{t(key)}:</Title>
          <Text>
            {['bookDate', 'sentDate'].includes(key) ? (
              moment(order[key]).format('DD.MM.YY - HH:mm')
            ) : key === 'status' ? t(`statuses:${order[key]}`) : order[key]}
          </Text>
        </ModalItem>
      ))
  }

  clearFields = () => {
    this.setState({
      canAddOrder: false,
      canChangeCell: false,
      identificator: '',
      bookSystemId: '',
      size: ''
    })
  }

  cellChanged = () => {
    const { box, refreshBox, refresh } = this.props;
    refreshBox(box.id);
    refresh();
    this.clearFields()
  }

  changeCell = () => {
    const { t, box } = this.props;

    questionAlert(t('toTransfer'), () => {
      transferOrder(box.order.id, this.state.size)
        .then(({ data }) => {
          try {
            const { boxInfo: { column, row } } = data;
            notify('success', t('orderTransferred'), t('rowColumn', { column, row }), this.cellChanged)
          } catch (e) {
            this.cellChanged()
          }
        })
        .catch(err => handleError(err))
    })
  }

  actionButtons = (action, disabled) => (
    <>
      <ActionButton
        backgroundColor="#FF4607"
        title={this.props.t('actions.cancel')}
        icon="close"
        size="small"
        click={this.clearFields}
      />
      <ActionButton
        backgroundColor="#02C100"
        title={this.props.t(`actions.${action}`)}
        icon="add"
        size="small"
        disabled={disabled}
        click={this[action]}
      />
    </>
  )

  render() {
    const { systemId, bookSystemId, size, canAddOrder, identificator, canChangeCell } = this.state;
    const { t, box, closeInfo, systems } = this.props;
    const currentSystem = systems && systems.find(s => s.id === systemId);
    const bookSystem = systems && systems.find(s => s.id === bookSystemId);

    return (
      <React.Fragment>
        <FlexRow alignItems="center" justifyContent="flex-end">
          <Close click={closeInfo} />
        </FlexRow>
        { box.order && box.order.id && this.getOrderInfo(box.order) }
        {
          (!box.order || !box.order.id) || (box.order && ['SENT', 'RESERVED'].includes(box.order.status)) ? (
            <>
              <LabelWithBorder>{t('actions.order')}</LabelWithBorder>
              <FlexRow alignItems="center" innerSpace="0.5rem">
                {(!box.order || !box.order.id) && !canAddOrder && (
                  <ActionButton
                    title={t('actions.addOrder')}
                    icon="add"
                    size="small"
                    click={() => this.setState({ canAddOrder: true })}
                  />
                )}
                {box.order && ['SENT', 'RESERVED'].includes(box.order.status) && !canChangeCell && (
                  <ActionButton
                    title={t('actions.changeCell')}
                    icon="compare_arrows"
                    size="small"
                    click={() => this.setState({ canChangeCell: true })}
                  />
                )}
                {
                  box.order && ['SENT'].includes(box.order.status) ? (
                    <ActionButton
                      backgroundColor="#FF4607"
                      title={t("openCellRemotely")}
                      icon="lock_open"
                      size="small"
                      click={this.openRemotely}
                    />
                  ) : null
                }
              </FlexRow>
            </>
          ) : null
        }

        {/* ПРИВЯЗАТЬ ЗАКАЗ */}
        {canAddOrder ? (
          <FlexColumn>
            <FlexRow padding="1rem 0" innerSpace="0.25rem" wrapItems>
              <BasicInput
                label={t('identificator')}
                name="identificator"
                value={identificator}
                type="text"
                onChange={this.handleChange}
                required
              />

              <CustomSelect2
                value={bookSystem && (bookSystem.name || bookSystem.id)}
                placeholder={t('chooseSystem')}
              >
                {systems.map((s, i) => (
                  <Option key={i} onClick={() => this.setState({ bookSystemId: s.id })}>
                    {s.name || s.id}
                  </Option>
                ))}
              </CustomSelect2>
            </FlexRow>
            <FlexRow innerSpace="0.25rem">
              {this.actionButtons('addOrder', (!identificator || !bookSystemId))}
            </FlexRow>
          </FlexColumn>
        ) : null}

        {/* СМЕНИТЬ ЯЧЕЙКУ */}
        {
          canChangeCell ? (
            <FlexColumn marginTop="1rem">
              <Label>{t('bookCell:cellSize')} *</Label>
              <FlexRow alignItems="center" innerSpace="0.25rem" wrapItems>
                {cellSizes.map((s, index) => (
                  <Radio
                    key={index}
                    id={s}
                    name={'size'}
                    title={t(`cellSizes:${s}`)}
                    value={s}
                    checked={size === s}
                    onChange={this.handleChange}
                  />
                ))}
              </FlexRow>
              <FlexRow innerSpace="0.25rem" marginTop="1rem">
                {this.actionButtons('changeCell', !size)}
              </FlexRow>
            </FlexColumn>
          ) : null
        }

        <LabelWithBorder>{t("actions.cell")}</LabelWithBorder>
        <FlexRow alignItems="center" innerSpace="0.5rem">
          <ActionButton
            title={t("open")}
            icon="lock_open"
            size="small"
            click={this.open}
          />
          {box.status === "ON" ? (
            <ActionButton
              backgroundColor="#FF4607"
              title={t("deactivate")}
              icon="block"
              size="small"
              click={this.block}
            />
          ) : null}
          {box.status === "RESERVED" && box.order == undefined ? (
            <ActionButton
              backgroundColor="#208C25"
              title={t("activate")}
              icon="block"
              size="small"
              click={this.unblock}
            />
          ) : null}
        </FlexRow>
        <LabelWithBorder>
          {t('actions.boxLoan')}{' '}
          {box.from && box.to
            ? `${t('from')} ${moment(box.from).format('DD.MM.YY')} ${
              t('to')} ${moment(box.to).format('DD.MM.YY')}`
            : null}
        </LabelWithBorder>
        <FlexRow alignItems="center" innerSpace="0.5rem">
          {box.from && box.to ? (
            <React.Fragment>
              <ActionButton
                title={t('actions.editLoan')}
                icon="edit"
                size="small"
                click={() => this.setState({ canEditLoan: true })}
              />
              <ActionButton
                backgroundColor="#FF4607"
                title={t('actions.removeLoan')}
                icon="remove"
                size="small"
                click={this.remove}
              />
            </React.Fragment>
          ) : (
            <ActionButton
              title={t('actions.addLoan')}
              icon="add"
              size="small"
              click={() => this.setState({ canAddLoan: true })}
            />
          )}
        </FlexRow>
        {this.state.canAddLoan ? (
          <FlexRow padding="1rem 0" innerSpace="0.25rem" wrapItems>
            <SortByPeriod
              parentCallback={this.sortOrdersByPeriod}
              onlyPeriod={true}
            />
            <CustomSelect2
              value={currentSystem && (currentSystem.name || currentSystem.id)}
              placeholder={t("chooseSystem")}
            >
              {systems.map((s, i) => (
                <Option
                  key={i}
                  onClick={() => this.setState({ systemId: s.id })}
                >
                  {s.name || s.id}
                </Option>
              ))}
            </CustomSelect2>
            <ActionButton
              icon="add"
              size="small"
              disabled={!this.state.from || !this.state.to || !systemId}
              click={this.create}
            />
          </FlexRow>
        ) : this.state.canEditLoan ? (
          <FlexRow padding="1rem 0" innerSpace="0.25rem" wrapItems>
            <SortByPeriod
              parentCallback={this.sortOrdersByPeriod}
              from={this.state.from}
              to={this.state.to}
              onlyPeriod={true}
            />
            <CustomSelect2
              value={currentSystem && (currentSystem.name || currentSystem.id)}
              placeholder={t("chooseSystem")}
            >
              {systems.map((s, i) => (
                <Option
                  key={i}
                  onClick={() => this.setState({ systemId: s.id })}
                >
                  {s.name || s.id}
                </Option>
              ))}
            </CustomSelect2>
            <ActionButton
              icon="edit"
              size="small"
              disabled={!this.state.from || !this.state.to || !systemId}
              click={this.edit}
            />
          </FlexRow>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({ systems: state.systems });

export default withNamespaces("cellInfo")(connect(mapStateToProps)(CellInfo));
