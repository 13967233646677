import * as React from "react";
import styled from "styled-components";
import { FlexRow } from "./flexboxes";
import { Paragraph } from "./fonts";
import check from "../resources/img/icons/check_circle.svg";
import error from "../resources/img/icons/baseline-error.svg";
import warning from "../resources/img/icons/grey_warning.svg";

const Advantages = styled.ul`
  padding-left: 0;
  list-style-type: none;
`;

const Advantage = styled.li`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 50%;
  margin-bottom: 0.25rem;
  &:nth-of-type(2n) {
    padding-left: 0.75rem;
  }
  &:before {
    content: "";
    display: inline-block;
    width: 0.875rem;
    height: 0.875rem;
    background: url("${props =>
      props.status === "ERROR"
        ? error
        : props.status === "SUCCESS"
        ? check
        : warning}") no-repeat;
    background-size: contain;
    margin-right: 0.65rem;
  }
`;

export const Advangates = ({ items }) => {
  return (
    <FlexRow as={Advantages} wrapItems>
      {items.map((i, index) => (
        <Paragraph as={Advantage} key={index} status={i.status}>
          {i.title}
        </Paragraph>
      ))}
    </FlexRow>
  );
};
