import styled from 'styled-components';

export const Container = styled.div`
  padding: 1.5rem 2rem;
  background: #F4F5FA;
  margin-left: 8.5rem;
  margin-top: 70px;
  flex: 1;
  min-height: calc(100vh - 119px);
  max-width: 1366px;
  transition: margin 1s ease;
  @media (max-width: 768px) {
    margin-left: 0;
  }
`;