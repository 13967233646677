import * as React from "react";
import styled, { keyframes } from "styled-components";

export const Button = styled.button`
  cursor: pointer;
  border: 0;
  border-radius: 5px;
  background: ${props =>
    props.disabled ? "#B4B4B4" : props.primary ? "#ea7851" : "#F05A28"};
  font-weight: 500;
  line-height: normal;
  font-size: ${props =>
    props.big ? "1.125rem" : props.small ? "0.75rem" : "1rem"};
  text-align: center;
  text-transform: uppercase;
  padding: ${props => (props.small ? "0.5rem" : "0.85em 1em")};
  color: #ffffff;
  width: ${props => (props.small ? "auto" : "100%")};
  min-width: ${props => (props.small ? "auto" : "100px")};
  max-width: ${props => (props.fullWidth ? "none" : "150px")};
  &:focus {
    transform: translateY(1px);
  }
`;

export const Action = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: ${props =>
    props.disabled
      ? "#B4B4B4"
      : props.primary
      ? "#ea7851"
      : props.backgroundColor || "#40a8e7"};
  border-radius: 0;
  text-align: left;
  box-shadow: 1px 0 5px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  & .material-icons {
    font-size: 1.125rem;
    margin-right: ${props => (props.onlyIcon ? "" : "0.5em")};
  }
`;

export const ActionButton = ({ ...props }) => {
  return (
    <Action
      type={props.type || "button"}
      small={props.size === "small"}
      backgroundColor={props.backgroundColor}
      disabled={props.disabled}
      primary={props.primary}
      onClick={props.click}
      onlyIcon={!props.title}
      fullWidth
    >
      {props.icon && <i className="material-icons">{props.icon}</i>}
      {props.title}
    </Action>
  );
};

const refreshAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Reload = styled.button`
  border: 0;
  padding: 0;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 50%;
  color: #6b6f82;
  background: transparent;
  cursor: pointer;
  animation: 0.5s ${props => (props.roll ? refreshAnimation : "")} infinite;
  > i {
    font-size: 1.125rem;
    font-weight: 700;
  }
`;

export const Refresh = ({ click, isLoading }) => {
  return (
    <Reload onClick={click} roll={isLoading}>
      <i className="material-icons">refresh</i>
    </Reload>
  );
};

const CloseBtn = styled.button`
  background: transparent;
  color: #000;
  border: 0;
  padding: 0;
  text-align: center;
  margin: 0;
  cursor: pointer;
  > i {
    font-size: 1.25rem;
  }
`;

export const Close = ({ click }) => {
  return (
    <CloseBtn onClick={click}>
      <i className="material-icons">close</i>
    </CloseBtn>
  );
};
