import styled from "styled-components";

export const Title = styled.h3`
  font-style: normal;
  line-height: normal;
  font-size: 1.125rem;
  font-weight: 500;
  color: #6B6F82;
  text-transform: uppercase;
  margin: ${props => props.margin || ''};
  margin-bottom: ${props => props.marginBottom || ''};
`;