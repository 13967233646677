import * as React from "react";
import { withNamespaces } from "react-i18next";
import styled from "styled-components";

const PageNotFound = styled.div`
  width: 100%;
  height: 100vh;
  background: linear-gradient(#FFF, #f05a28);
  color: #FFF;
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const PageNotFoundCode = styled.h1`
  color: #f05a28;
  margin: 0;
  font-weight: 700;
  font-size: 4rem;
`;

const PageNotFoundTitle = styled.h1`
  color: #f05a28;
  margin: 0;
  font-weight: 300;
`;

const GoBack = styled.button`
  font-size: 1rem;
  color: #f05a28;
  border: 1px solid #f05a28;
  padding: 0.5rem 1rem;
  margin: 1em 0;
  cursor: pointer;
  background: transparent;
  &:hover {
    background: #f05a28;
    color: #FFFFFF;
  }
`;

class NotFound extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <PageNotFound>
        <PageNotFoundCode>404</PageNotFoundCode>
        <PageNotFoundTitle>{t('pageNotFound')}</PageNotFoundTitle>
        <GoBack onClick={() => window.history.back()}>{t('goToPreviousPage')}</GoBack>
      </PageNotFound>
    );
  }
}

export default withNamespaces('global')(NotFound);