import axios from "axios";
import store from "../config/store";
import history from "../config/history";
import { Cookies } from "react-cookie";

const cookies = new Cookies();
let refreshTokenPromise;

export function logIn(phoneNumber, password) {
  const data = { phone: phoneNumber, password: password };
  return axios.put(`/auth/login`, data);
}

export function checkPhoneNumber(phoneNumber) {
  return axios.get(`/auth/phones/${phoneNumber}/exists`);
}

export function initUser(phone) {
  return axios.post(`/auth/initialize`, { phone: phone });
}

export function sendSms(phoneNumber) {
  return axios.put(`/auth/phones/${phoneNumber}/sms`, "");
}

export function confirmSmsCode(userId, phoneNumber, smsCode) {
  const data = { id: userId, phone: phoneNumber, code: smsCode };
  return axios.put(`/auth/phones/confirm `, data);
}

export function setPassword(userId, phoneNumber, smsCode, password) {
  const data = {
    id: userId,
    phone: phoneNumber,
    code: smsCode,
    password: password
  };
  return axios.put(`/auth/password `, data);
}

export function getCurrentUser() {
  store.dispatch({ type: "CURRENT_USER_LOADING", payload: true });
  return axios.get(`/a/users/current-user`)
    .then(res => {
      cookies.set("user", res.data, { path: "/" });
      store.dispatch({ type: "CURRENT_USER_LOADING", payload: false });
      store.dispatch({ type: "SET_USER", payload: res.data });
      if (history.location.pathname === "/") history.push("/lockers");
    })
    .catch(() => history.push("/authorization"));
}

export async function refreshToken() {
  const token = cookies.get('token');
  const response = await fetch('/platform/v1/rest/a/auth/refresh-token', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  })

  return await response.json()
}

const parseJwt = token => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export function checkToken() {
  return new Promise(resolve => {
    const token = cookies.get('token');
    if (token) {
      const { iat, expireDate } = parseJwt(token);
      const expireTime = expireDate - (iat * 1000);
      const halfOfExpireDate = new Date(expireDate - (expireTime / 2));
      if (new Date() > halfOfExpireDate) {
        if (!refreshTokenPromise) {
          refreshTokenPromise = refreshToken().then(res => {
            refreshTokenPromise = null;
            if (res.token) {
              cookies.set('token', res.token, { path: '/' });
              return res.token;
            }

            throw new Error('expired')
          });
        }

        return refreshTokenPromise
          .then(newToken => resolve(newToken))
          .catch(() => resolve(null));
      } else {
        resolve(token)
      }
    } else {
      resolve(null)
    }
  })
}

export function afterLogout() {
  cookies.remove('token');
  cookies.remove('user');
  history.push('/authorization');
}

export function logout() {
  return axios.put('/a/auth/logout', {})
    .then(() => afterLogout())
    .catch(() => afterLogout())
}
