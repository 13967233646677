import * as React from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import Modal from "../../components/modal";
import { BasicInput} from "../../components/basicInput";
import { Flex } from "../../components/flexboxes";
import { ActionButton } from "../../components/button";
import { createSystem, changeSystem } from "../../services/system.service";
import { handleError } from "../../services/other.service";
import { Checkbox } from "../../components";

class SystemInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      verified: true,
      basicIntegration: false,
      advancedIntegration: false,
      dropSms: false,
      system: {
        id: props.currentSystem.id || "",
        identifier: props.currentSystem.identifier || "",
        name: props.currentSystem.name || "",
        verified: props.currentSystem.verified || false,
        basicIntegration: props.currentSystem.basicIntegration || false,
        advancedIntegration: props.currentSystem.advancedIntegration || false,
        infoUrl: props.currentSystem.infoUrl || "",
        statusUrl: props.currentSystem.statusUrl || "",
        callCenterNumber: props.currentSystem.callCenterNumber || "",
        dropSms: props.currentSystem.dropSms || false
      },
      fields: [
        { name: "id", type: "text" },
        { name: "identifier", type: "text" },
        { name: "name", type: "text" },
        { name: "infoUrl", type: "text" },
        { name: "statusUrl", type: "text" },
        { name: "callCenterNumber", type: "text" },
        { name: "verified", type: "checkbox" },
        { name: "basicIntegration", type: "checkbox" },
        { name: "advancedIntegration", type: "checkbox" },
        { name: "dropSms", type: "checkbox" },
      ]
    };
  }

  handleChange = e => {
    let { name, value, checked } = e.target;
    if (name === 'verified' ||
      name === 'basicIntegration' ||
      name === 'advancedIntegration' ||
      name === 'dropSms') {
        value = checked;
        // if (value === 'on') value = true;
        // else value = false;
    } 
    this.setState(
      {
        system: {
          ...this.state.system,
          [name]: value
        }
      },
      () => console.log()
    );
  };

  savesystem = async e => {
    if (e) e.preventDefault();
    createSystem(this.state.system)
      .then(() => this.props.close(true))
      .catch(err => handleError(err));
  };

  editSystem = async e => {
    if (e) e.preventDefault();
    changeSystem(this.state.system.id, this.state.system)
      .then(() => this.props.close(true))
      .catch(err => handleError(err));
  };

  checkForm = () => {
    const { system } = this.state;
    return (
      !system.name
    );
  };

  render() {
    const { t, type, close } = this.props;
    const { fields } = this.state;
    return (
      <Modal
        id="createSystem"
        size="medium"
        title={type === "show" ? t("actions.show") : t("actions.create")}
        closeModal={() => close(null)}
      >
        <form onSubmit={this.savesystem}>
          {fields.map((f, index) => {
            if (f.type === "checkbox")
              return (
                <Checkbox
                    id={f.name}
                    name={f.name}
                    title={t(f.name)}
                    checked={this.state.system[f.name]}
                    onChange={this.handleChange}
                    disabled={type === "show"}
                />
              );
            else
              return (
                <BasicInput
                  key={index}
                  label={t(f.name)}
                  name={f.name}
                  value={this.state.system[f.name]}
                  type={f.type}
                  onChange={this.handleChange}
                  disabled={type === "show" || f.name === 'id'}
                />
              );
          })}
          {type === "create" ? (
            <Flex justifyContent="flex-end" marginTop="2em">
              <ActionButton
                type="submit"
                title={t("actions.create")}
                size="small"
                click={this.savesystem}
                disabled={this.checkForm()}
              />
            </Flex>
          ) : type === "edit" ? (
            <Flex justifyContent="flex-end" marginTop="2em">
              <ActionButton
                type="submit"
                title={t("actions.edit")}
                size="small"
                click={this.editSystem}
                disabled={this.checkForm()}
              />
            </Flex>
          ) :null}
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({ systems: state.systems });

export default withNamespaces("system")(connect(mapStateToProps)(SystemInfo));
