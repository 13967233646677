import styled from "styled-components";

export const Status = styled.div`
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  border-radius: 5px;
  line-height: normal;
  font-size: 0.875rem;
  padding: 0.5em
  color: #FFFFFF;
  max-width: 200px;
  margin: 0 auto;
  background: ${props =>
    props.type === "RESERVED"
      ? "#969EC5"
      : props.type === "UNRESERVED" || props.type === "WITHDRAWN"
      ? "#FFA282"
      : props.type === "SENT"
      ? "#5563E1"
      : props.type === "END"
      ? "#208C25"
      : props.type === "needToWithdraw" || props.type === "onWITHDRAWN"
      ? "#F05A28"
      : "#000"};
`;

export const StatusSmall = styled.div`
  background: ${props =>
    props.type === "registered" || props.type === "ready"
      ? "#208C25"
      : props.type === "invited"
      ? "#C4DE5C"
      : props.type === "disabled"
      ? "#D6D7DC"
      : "#000"};
  border-radius: 100px;
  text-transform: lowercase;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: normal;
  color: #ffffff;
  padding: 0.25em 0.5em;
  text-align: center;
  margin-left: 1em;
  margin: ${props => (props.alignCenter ? "0 auto" : "")};
  max-width: max-content;
`;

export const Roles = styled(StatusSmall)`
  background: ${props =>
  props.role === "LOGISTICIAN"
    ? "#208C25"
    : props.role === "OPERATOR"
    ? "#2e65c3"
    : props.role === "COURIER"
      ? "#dc8b36"
      : "#000"};
`;
