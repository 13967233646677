import styled from "styled-components";

export const Flex = styled.div`
  display: flex;
  width: ${p => (p.fullWidth ? "100%" : '')};
  justify-content: ${p => p.justifyContent || ''};
  align-items: ${p => p.alignItems || ''};
  border: ${p => p.withBorder ? "1px solid" : ''};
  padding: ${p => p.padding || ""};
  margin: ${p => p.innerSpace ? `-${p.innerSpace}`
    : p.margin ? p.margin : ''
  };
  margin-bottom: ${p => p.marginBottom || ''};
  margin-top: ${p => p.marginTop || ''};
  > * {
    margin: ${p => p.innerSpace ? `${p.innerSpace} !important` : ''};
  }
`;

export const FlexRow = styled(Flex)`
  flex-wrap: ${props => (props.wrapItems ? "wrap" : "")};
  flex-direction: row;
`;

export const FlexColumn = styled(Flex)`
  flex-direction: column;
`;

export const FlexWrap = styled(Flex)`
  flex-wrap: wrap;
`;

export const Row = styled(FlexWrap)`
  flex: ${props => (props.fullWidth ? 1 : "")};
  > * {
    margin-bottom: 0.5rem;
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.width || "50%"};
  margin: ${props => (props.innerSpace ? `-${props.innerSpace} 0` : "")};
  & > * {
    margin: ${props => (props.innerSpace ? `${props.innerSpace} 0` : "")};
  }
  &:first-of-type {
    padding-right: 1.5rem;
  }
  &:not(:first-of-type) {
    border-left: 1px solid #d6d7dc;
    padding-left: 1.5rem;
  }
  @media (max-width: 1023px) {
    width: 100%;
    &:first-of-type {
      padding-right: 0;
    }
    &:not(:first-of-type) {
      margin-top: 2rem;
      border-left: 0;
      padding-left: 0;
    }
  }
`;
