import * as React from "react";
import { Subtitle, Paragraph } from "../../components/fonts";
import { BasicInput, BasicInputFile } from "../../components/basicInput";
import { FlexRow, Column } from "../../components/flexboxes";
import { ActionButton } from "../../components/button";
import { AvatarLoader } from "../../components/avatarLoader";

export const FillCompanyInfo = ({
  t,
  application,
  image,
  scanOfTheRegistration,
  allInfoIsCorrect,
  settingsWorksCorrectly,
  inputChange,
  uploadFile,
  checkboxChange,
  sendRequest
}) => {
  return (
    <Column innerSpace="0.5rem">
      <Subtitle>{t("fillCompanyInfo")}</Subtitle>
      <AvatarLoader
        id="avatar"
        change={e => uploadFile("logos", e.target.files)}
        image={application.logo.url}
      />
      <Paragraph textAlign="center">{t("avatarInfo")}</Paragraph>
      <BasicInput
        label={t("companyName")}
        icon="domain"
        name="name"
        value={application.name}
        type="text"
        onChange={inputChange}
        required
      />
      <BasicInput
        label={t("nationalID")}
        icon="verified_user"
        name="identificator"
        value={application.identificator}
        type="tel"
        onChange={inputChange}
        required
      />
      <BasicInputFile
        id="scanOfTheRegistration"
        label={t("scanOfTheRegistration")}
        icon="library_books"
        name="scanOfTheRegistration"
        value={application.document.filename}
        onChange={e => uploadFile("documents", e.target.files)}
        fileTypes=".xls,.xlsx, .pdf, .doc, .docx"
        required
      />
      {/*<Label>{t("guarantee")}</Label>*/}
      {/*<Checkbox*/}
      {/*id="allInfoIsCorrect"*/}
      {/*name="allInfoIsCorrect"*/}
      {/*value={allInfoIsCorrect}*/}
      {/*isChecked={allInfoIsCorrect}*/}
      {/*onChange={checkboxChange}*/}
      {/*title={t("allInfoIsCorrect")}*/}
      {/*/>*/}
      {/*<Checkbox*/}
      {/*id="settingsWorksCorrectly"*/}
      {/*name="settingsWorksCorrectly"*/}
      {/*value={settingsWorksCorrectly}*/}
      {/*isChecked={settingsWorksCorrectly}*/}
      {/*onChange={checkboxChange}*/}
      {/*title={t("settingsWorksCorrectly")}*/}
      {/*/>*/}
      <FlexRow justifyContent="flex-end">
        <ActionButton
          title={t("saveForm")}
          icon="save"
          size="small"
          click={() => sendRequest("save")}
          primary
        />
      </FlexRow>
    </Column>
  );
};
