import * as React from "react";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";
import i18n from "../config/i18n";
import Navbar from "./main/navbar.js";
import { FooterBase } from "./main/footer.js";
import styled from "styled-components";
import connectLogo from "../resources/img/pickcell_connect.svg";
import { Loader } from "./main/loader.js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin: 6rem auto;
  padding: 2.75rem;
  max-width: 480px;
  @media (max-width: 512px) {
    margin: 3rem 1rem;
    padding: 1.5rem;
  }
`;

const FooterFullWidth = styled(FooterBase)`
  position: fixed;
  bottom: 0;
  width: 100%;
  margin-left: 0;
  @media (min-width: 1921px) {
    max-width: none;
    margin-left: 0;
    margin-right: 0;
  }
`;

const HR = styled.div`
  margin: 1.5rem 0;
  width: 100%;
  background: rgba(14, 14, 14, 0.25);
  height: 1px;
`;

const mapStateToProps = state => ({ loading: state.loading });

const loginLayout = Component => {
  return withCookies(
    connect(mapStateToProps)(
      class extends React.Component {
        render() {
          const { loading } = this.props;
          return (
            <div style={{ position: "relative", height: "100vh" }}>
              <Navbar position="static"/>
              <Container>
                <img src={connectLogo} alt="PickCell connect" />
                <HR />
                <Component {...this.props} />
                <HR />
              </Container>
              <FooterFullWidth>{i18n.t("copyright")}</FooterFullWidth>
              {loading ? <Loader /> : null}
            </div>
          );
        }
      }
    )
  );
};

export default loginLayout;
