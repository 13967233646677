import React from "react";
import { withNamespaces } from "react-i18next";
import Swal from "sweetalert2";
import moment from "moment";
import Modal, { ModalItem, Title, Text } from "../../components/modal";
import { FlexColumn, FlexRow } from "../../components/flexboxes";
import { approveOrDecline } from "../../services/applications.service";
import { handleError } from "../../services/other.service";
import { ActionButton } from "../../components/button";

class Application extends React.Component {
  makeAction = (id, action) => {
    approveOrDecline(id, action)
      .then(res => {
        Swal.fire("", this.props.t(`alerts:${action}`), "success").then(() =>
          this.props.close()
        );
      })
      .catch(err => handleError(err));
  };

  showInfo() {
    const { t, application } = this.props;
    const info = [
      {
        name: t("createDate"),
        value: moment(application.createDate).format("DD.MM.YY - HH:mm")
      },
      { name: t("name"), value: application.name },
      { name: t("status"), value: t(`statuses.${application.status}`) },
      { name: t("identificator"), value: application.identificator },
      { name: t("infoUrl"), value: application.infoUrl },
      { name: t("statusUrl"), value: application.statusUrl }
    ];
    return info.map((i, index) =>
      i.value ? (
        <ModalItem key={index}>
          <Title>{i.name}</Title>
          <Text>{i.value}</Text>
        </ModalItem>
      ) : null
    );
  }

  render() {
    const { t, application, close } = this.props;
    return application ? (
      <Modal size="medium" title={application.name} closeModal={close}>
        <FlexColumn alignItems="flex-start">
          {application.logo ? (
            <img
              src={application.logo.url}
              style={{ maxWidth: "100px" }}
              alt=""
            />
          ) : null}
          {application.document ? (
            <a
              href={application.document.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("download")}
            </a>
          ) : null}
          <hr />
          {this.showInfo()}
          <hr />
          {application.status === "IN_PROGRESS" ? (
            <FlexRow
              justifyContent="space-between"
              innerSpace="0.5rem"
              fullWidth
            >
              <ActionButton
                backgroundColor="#FF4607"
                title={t("decline")}
                icon="block"
                size="small"
                click={() => this.makeAction(application.id, "decline")}
              />
              <ActionButton
                title={t("approve")}
                icon="check"
                size="small"
                click={() => this.makeAction(application.id, "approve")}
              />
            </FlexRow>
          ) : null}
        </FlexColumn>
      </Modal>
    ) : null;
  }
}

export default withNamespaces("applications")(Application);
