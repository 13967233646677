import React from "react";
import styled from "styled-components";
import searchIcon from "../resources/img/icons/search.svg";

const SearchForm = styled.form`
  position: relative;
  font-size: 0.875rem;
`
const SearchInput = styled.input.attrs(() => ({ type: 'text' }))`
  line-height: normal;
  font-size: 1em;
  padding: 4px 8px;
  color: #67676e;
  border: 0;
  border-bottom: 1px solid #67676e;
  flex: 1;
  max-width: 200px;
  background: transparent;
  &::placeholder {
    font-weight: 300;
    font-style: italic;
    color: #969EC5;
  }
`;

const Submit = styled.button.attrs(() => ({ type: 'submit' }))`
  position: absolute;
  right: 0.5em;
  background: transparent url("${searchIcon}") no-repeat left center;
  background-size: 1em;
  height: 100%;
  width: 16px;
  border: 0;
  cursor: pointer;
`

export const Search = ({ submit, ...rest }) => {

  const handleSubmit = e => {
    e.preventDefault()
    if (!!submit) {
      submit()
    }
  }

  return (
    <SearchForm onSubmit={handleSubmit}>
      <SearchInput {...rest} />
      <Submit />
    </SearchForm>
  )
}
