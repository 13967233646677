import * as React from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import Modal from "../../components/modal";
import {
  BasicInput,
  BasicDatepicker,
  BasicSelect
} from "../../components/basicInput";
import styled from "styled-components";
import { Flex, FlexRow } from "../../components/flexboxes";
import { ActionButton } from "../../components/button";
import { createTariff, removeTariff, changeTariff, getTariffs } from "../../services/billing.service";
import { handleError } from "../../services/other.service";

export const Label = styled.label`
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
  line-height: normal;
  padding: 0;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.5em;
  margin-top: 1em;
`;

class CreateTariff extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tarifs: [],
      systems: [],
      tariff: {
        id: props.currentTariff.id || "",
        systemId: props.currentTariff.systemId || "",
        // startDate: props.currentTariff.startDate
        //   ? moment(props.currentTariff.startDate).format("YYYY-MM-DD")
        //   : "",
        // endDate: props.currentTariff.endDate
        //   ? moment(props.currentTariff.endDate).format("YYYY-MM-DD")
        //   : "",
        bookHours: props.currentTariff.bookHours || 0,
        bookPrice: props.currentTariff.bookPrice || 0,
        bookFreeHours: props.currentTariff.bookFreeHours || 0,
        // unbookHours: props.currentTariff.unbookHours || "",
        // unbookPrice: props.currentTariff.unbookPrice || "",
        dropStorageHours: props.currentTariff.dropStorageHours || 0,
        dropStoragePrice: props.currentTariff.dropStoragePrice || 0,
        extraStorageHours: props.currentTariff.extraStorageHours || 0,
        extraStoragePrice: props.currentTariff.extraStoragePrice || 0,
        // withdrawHours: props.currentTariff.withdrawHours || "",
        // withdrawPrice: props.currentTariff.withdrawPrice || "",
        // withdrawCourierHours: props.currentTariff.withdrawCourierHours || "",
        withdrawCourierPrice: props.currentTariff.withdrawCourierPrice || 0,
        // expirationHours: props.currentTariff.expirationHours || "",
        // expirationPrice: props.currentTariff.expirationPrice || "",
        deliveryPrice: props.currentTariff.deliveryPrice || 0,
        // extraBookPricePerDay: props.currentTariff.extraBookPricePerDay || "",
      },
      fields: {
        system: [
          { name: "systemId", type: "select",}
        ],
        // date: [
        //   { name: "startDate", type: "date" },
        //   { name: "endDate", type: "date" },
        // ],
        book: [
          { name: "bookHours", type: "number" },
          { name: "bookPrice", type: "number" },
          { name: "bookFreeHours", type: "number" },
        ],
        delivery: [
          { name: "deliveryPrice", type: "number" },
        ],
        drop: [
          { name: "dropStorageHours", type: "number" },
          { name: "dropStoragePrice", type: "number" },
        ],
        extraStorage: [
          { name: "extraStorageHours", type: "number" },
          { name: "extraStoragePrice", type: "number" },
        ],
        withdraw: [
          { name: "withdrawCourierPrice", type: "number" },
        ],
        // other: [
        //   { name: "unbookHours", type: "number" },
        //   { name: "unbookPrice", type: "number" },
        //   { name: "withdrawHours", type: "number" },
          
        //   { name: "withdrawCourierHours", type: "number" },
        //   { name: "withdrawCourierPrice", type: "number" },
        //   { name: "expirationHours", type: "number" },
        //   { name: "expirationPrice", type: "number" },
        // ],
        // { name: "returnPrice", type: "number" },
        // { name: "extraBookPricePerDay", type: "number" },
      }
    };
  }

  componentDidMount() {
    // console.log(this.props.systems);
    this.getTariffList();
  }

  getTariffList = () => {
    getTariffs()
      .then(res => {
        this.state.systems = this.props.systems.filter(el => {
          for (let i = 0; i < res.data.list.length; i++) {
            if (res.data.list[i].systemId === el.id) {
              return false
            }
          }
          return true;
        });

        this.setState({});
      })
      .catch(err => {
        console.log(err);
        handleError(err)
      });
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState(
      {
        tariff: {
          ...this.state.tariff,
          [name]:
            name === "startDate" || name === "endDate" || name === "systemId"
              ? value
              : parseInt(value)
        }
      },
      () => console.log()
    );
  };

  saveTariff = async e => {
    if (e) e.preventDefault();
    createTariff(this.state.tariff)
      .then(() => this.props.close(true))
      .catch(err => handleError(err));
  };

  editTariff = async e => {
    if (e) e.preventDefault();
    changeTariff(this.state.tariff)
      .then(() => this.props.close(true))
      .catch(err => handleError(err));
  };

  removeTariff = async e => {
    if (e) e.preventDefault();
    removeTariff(this.state.tariff.systemId, this.state.tariff.id)
      .then(() => this.props.close(true))
      .catch(err => handleError(err));
  };

  checkForm = () => {
    const { tariff } = this.state;
    return (
      !tariff.systemId ||
      !tariff.bookHours ||
      !tariff.bookPrice ||
      !tariff.bookFreeHours
    );
  };

  render() {
    let { t, systems, type, close } = this.props;
    // this.state.systems = systems;

    const { fields, tariff } = this.state;
    return (
      <Modal
        id="createTariff"
        size="medium"
        title={type === "show" ? t("actions.show") : type === "edit" ? t("actions.edit") : t("actions.create")}
        closeModal={() => close(null)}
      >
        <form>
          {Object.entries(fields).map((i, index1) => {
            return (
              <div style={{marginTop: '1em'}}>
                {i[0] != 'system' ? <Label>{t(i[0])}</Label> : null}
                <Flex justifyContent="space-around;" marginTop="1em">
                
                  {i[1].map((f, index) => {
                    if (f.type === "date")
                      return (
                        <BasicDatepicker
                          key={index}
                          label={t(f.name)}
                          name={f.name}
                          min={f.name === "endDate" ? tariff.startDate : ""}
                          max={f.name === "startDate" ? tariff.endDate : ""}
                          value={tariff[f.name]}
                          onChange={this.handleChange}
                          disabled={type === "show"}
                        />
                      );
                    else if (f.type === "select")
                      return (
                        <BasicSelect
                          key={index}
                          label={t(f.name)}
                          name={f.name}
                          value={tariff[f.name]}
                          options={this.state.systems}
                          onChange={this.handleChange}
                          disabled={type === "show" || type === "edit"}
                        />
                      );
                    else
                      return (
                        <BasicInput
                          min="0"
                          max="9999"
                          key={index}
                          label={t(f.name)}
                          name={f.name}
                          value={this.state.tariff[f.name]}
                          type={f.type}
                          onChange={this.handleChange}
                          disabled={type === "show"}
                        />
                      );
                    })
                  }
              </Flex>
            </div>)
          })}

          {type === "create" ? (
            <Flex justifyContent="flex-end" marginTop="2em">
              <ActionButton
                type="submit"
                title={t("actions.create")}
                size="small"
                click={this.saveTariff}
                disabled={this.checkForm()}
              />
            </Flex>
          ) : type === "edit" ? (
            <Flex justifyContent="flex-end" marginTop="2em">
              {/* <ActionButton
                backgroundColor="#F05A28"
                type="submit"
                title={t("actions.remove")}
                size="small"
                click={this.removeTariff}
              /> */}
              <ActionButton
                type="submit"
                title={t("actions.edit")}
                size="small"
                click={this.editTariff}
                disabled={this.checkForm()}
              />
            </Flex>
          ) :null}
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({ systems: state.systems });

export default withNamespaces("tariff")(connect(mapStateToProps)(CreateTariff));
