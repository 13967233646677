import styled from "styled-components";
import union from "../resources/img/union.svg";

export const Timeline = styled.div`
  position: relative;
  background: url("${union}") no-repeat;
  width: 100%;
  height: 159px;
`;

const point = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  padding-left: 2em;
  font-size: 0.75rem;
  line-height: normal;
  font-weight: ${props => props.active ? 400 : 300};
  color: ${props => props.active ? '#F05A28' : '#A6A2A2'};
  max-height: 50px;
  overflow: hidden;
  &:before {
    content: '';
    height: 13px;
    width: 13px;
    background: #FF9671;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 0;
    opacity: ${props => props.active ? 1 : 0}
  }
`;

export const PointUser = styled.span`
  font-size: 0.75rem;
  color: #A6A2A2;
`;

export const FirstPoint = styled(point)`
  top: 4px;
  left: 6px;
`;

export const SecondPoint = styled(point)`
  top: calc(50% - 9px);
  left: 6px;
`;
export const ThirdPoint = styled(point)`
  top: calc(100% - 21px);
  left: 6px;
`;
