import styled from "styled-components";

export const LabelWithBorder = styled.label`
  font-weight: normal;
  line-height: normal;
  font-size: 1.125rem;
  color: #525252;
  margin: ${props => props.margin || "1.25em 0 0.5em"};
  width: 100%;
  padding-bottom: 0.25rem;
  border-bottom: 0.75px solid #000000;
`;
