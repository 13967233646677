import * as React from "react";
import { withNamespaces } from "react-i18next";
import MainLayout from "../layouts/main";
import styled from "styled-components";
import { ContentCard } from "../components/contentCard";
import {
  Answers,
  FaqRow,
  Question,
  Questions
} from "../components/faq";
import { Title } from "../components/title";
import {
  getDictionaries,
  deleteDictionary,
  getFields,
  deleteField
} from "../services/dictionary.service";
import { handleError, questionAlert } from "../services/other.service";
import { Flex, Row } from "../components/flexboxes";
import { ActionButton } from "../components/button";
import CreateUpdateDictionary from "./modals/createDictionary";

const Action = styled.button`
  display: none;
  border: 0;
  background: transparent;
  cursor: pointer;
  padding: 0 0.25rem;
  > i {
    font-size: 0.875rem;
  }
`;

const Dictionary = styled(Question)`
  display: flex;
  justify-content: space-between;
  > span {
    flex: 1;
  }
  &:hover {
    ${Action} {
      display: block;
    }
  }
  ${Action} {
    > i {
      color: ${props => (props.active ? "#FFF" : "#000")};
    }
  }
`;

const selected = {
  dictionary: {
    code: "",
    ru: "",
    kk: "",
    en: ""
  },
  field: {
    key: "",
    ru: "",
    kk: "",
    en: ""
  }
};

class Dictionaries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dictionaries: [],
      fields: [],
      selected: selected,
      action: "",
      type: "",
      showModal: false
    };
  }

  componentDidMount() {
    this.loadDictionaries();
  }

  loadDictionaries = () => {
    getDictionaries()
      .then(res =>
        this.setState({
          dictionaries: res.data.list,
          selected: selected
        })
      )
      .catch(err => handleError(err));
  };

  handleChangeSelected = (e, type) => {
    const { name, value } = e.target;
    this.setState({
      selected: {
        ...this.state.selected,
        [type]: {
          ...this.state.selected[type],
          [name]: value
        }
      }
    });
  };

  create = type => {
    this.setState({
      action: "add",
      type: type,
      selected: {
        ...this.state.selected,
        [type]: selected[type]
      },
      showModal: true
    });
  };

  edit = (type, item) => {
    this.setState({
      action: "edit",
      type: type,
      selected: {
        ...this.state.selected,
        [type]: item
      },
      showModal: true
    });
  };

  removeDictionary = code => {
    questionAlert(this.props.t("dictionaryWillBeRemoved"), () =>
      deleteDictionary(code)
        .then(() => this.loadDictionaries())
        .catch(err => handleError(err))
    );
  };

  loadFields = dictionary => {
    getFields(dictionary.code)
      .then(res =>
        this.setState({
          fields: res.data.list,
          selected: {
            ...this.state.selected,
            dictionary: dictionary
          }
        })
      )
      .catch(err => handleError(err));
  };

  removeField = key => {
    questionAlert(this.props.t("fieldWillBeRemoved"), () =>
      deleteField(this.state.selected.dictionary.code, key)
        .then(() => this.loadFields(this.state.selected.dictionary))
        .catch(err => handleError(err))
    );
  };

  closeModal = changed => {
    this.setState({ showModal: false }, () => {
      if (changed) {
        if (this.state.type === "dictionary") this.loadDictionaries();
        else if (this.state.type === "field")
          this.loadFields(this.state.selected.dictionary);
      }
    });
  };

  render() {
    const { t } = this.props;
    const { showModal, dictionaries, fields, selected } = this.state;
    return (
      <React.Fragment>
        <Row justifyContent="flex-start" alignItems="center" fullWidth>
          <Title>{t("dictionary")}</Title>
          <ActionButton
            icon="add"
            size="small"
            click={() => this.create("dictionary")}
          />
        </Row>
        <ContentCard>
          <FaqRow>
            <Questions width="25%">
              {dictionaries.map((d, index) => (
                <Dictionary key={index} active={selected.dictionary === d}>
                  <span onClick={() => this.loadFields(d)}>{d.code}</span>
                  <Flex>
                    <Action onClick={() => this.edit("dictionary", d)}>
                      <i className="material-icons">edit</i>
                    </Action>
                    <Action onClick={() => this.removeDictionary(d.code)}>
                      <i className="material-icons">remove</i>
                    </Action>
                  </Flex>
                </Dictionary>
              ))}
            </Questions>
            <Answers width="75%">
              {selected.dictionary.code && (
                <ActionButton
                  icon="add"
                  size="small"
                  click={() => this.create("field")}
                />
              )}

              {fields.map((f, index) => (
                <Dictionary key={index} active={selected.field === f}>
                  <span>{f.key}</span>
                  <Flex>
                    <Action onClick={() => this.edit("field", f)}>
                      <i className="material-icons">edit</i>
                    </Action>
                    <Action onClick={() => this.removeField(f.key)}>
                      <i className="material-icons">remove</i>
                    </Action>
                  </Flex>
                </Dictionary>
              ))}
              {/*<BasicInput*/}
              {/*type="text"*/}
              {/*name="ru"*/}
              {/*value={selected.dictionary.ru}*/}
              {/*onChange={this.handleChangeSelected}*/}
              {/*/>*/}
            </Answers>
          </FaqRow>
        </ContentCard>
        {showModal ? (
          <CreateUpdateDictionary
            action={this.state.action}
            type={this.state.type}
            close={changed => this.closeModal(changed)}
            selected={selected}
            handleChange={this.handleChangeSelected}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default withNamespaces("dictionaries")(MainLayout(Dictionaries));
