export const FAQ_QUESTIONS = {
  ru: [
    {
      key: "",
      title: ""
    }
  ],
  en: [
    {
      key: "intro",
      title: "INTRODUCTION"
    },
    {
      key: "howToConnect",
      title: "How to connect",
      child: [{ key: "basic", title: "Basic integration" }]
    }
  ],
  kk: [
    {
      key: "",
      title: ""
    }
  ]
};

export const FAQ_ANSWERS = {
  ru: [
    {
      key: "",
      html: ""
    }
  ],
  en: [
    {
      key: "intro",
      html: "<title>Introduction</title>"
    },
    {
      key: "basic",
      html:
        "<h3>Basic integration</h3><p><b>1. Callback URL for getting parcel info</b></p>\n" +
        "<p>You have to develop REST API which will able our platform to make request to your system.</p>\n" +
        "<p>URL should be developed by the following format:</p>\n" +
        '<code aria-label="GET">http://&lt;yourCallbackURL&gt;/&lt;trackNumber&gt;</code>' +
        "<p><b>trackNumber</b> - orem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>" +
        "<p>Request should return as JSON withn following format:</p>" +
        "<code>{<br />" +
        '&nbsp;&nbsp;"trackNumber": string,<br />' +
        '&nbsp;&nbsp;"mobilePhone": string,<br />' +
        '&nbsp;&nbsp;"address": string,<br />' +
        '&nbsp;&nbsp;"lockerIndex": string,<br />' +
        '&nbsp;&nbsp;"parcelValue": number<br />' +
        "}</code>"
    }
  ],
  kk: [
    {
      key: "",
      html: ""
    }
  ]
};
