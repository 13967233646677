import React from "react";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import { CookiesProvider } from "react-cookie";

import i18n from "./config/i18n";
import store from "./config/store";
import history from "./config/history";
import axiosInterceptorsConfig from "./config/axios-config";
import Pages from "./pages";
import {Router} from "react-router-dom";
import { getCurrentUser } from "./services/authorization.service";
import { Cookies } from "react-cookie";

const cookies = new Cookies();

axiosInterceptorsConfig();

export default () => {
  if (!cookies.get("token") || !cookies.get("user"))
    history.push("/authorization");
  else getCurrentUser();

  if (!cookies.get("i18lang")) {
    console.log(navigator.language);
    i18n.changeLanguage(
      navigator.language ? navigator.language.substr(0, 2) : "ru"
    );
    cookies.set(
      "i18lang",
      navigator.language ? navigator.language.substr(0, 2) : "ru",
      { path: "/" }
    );
  }
  return (
    <Provider store={store}>
      <CookiesProvider>
        <I18nextProvider i18n={i18n}>
          <Router history={history}>
            <Pages/>
          </Router>
        </I18nextProvider>
      </CookiesProvider>
    </Provider>
  )
};
