import i18n from "../config/i18n";
import Swal from "sweetalert2";
import axios from "axios";
import store from "../config/store";
import { afterLogout } from "./authorization.service";

export function getSystems() {
  axios.get('/a/systems')
    .then(res =>
      store.dispatch({ type: "GET_SYSTEMS", payload: res.data.list })
    )
    .catch(err => handleError(err));
}

function _sessionExpiredError() {
  Swal
    .fire(i18n.t('global:error'), i18n.t('global:sessionExpired'), 'error')
    .then(() => afterLogout())
}

export function handleError(error) {
  if (error === 'sessionExpired') {
    _sessionExpiredError()
  } else {
    let err = '';
    try {
      const message = JSON.parse(error.response.data.message);
      err = message[i18n.language] || message.ru;
    } catch (e) {
      err = i18n.t("global:unexpectedError");
    }
    Swal.fire(i18n.t("global:error"), err, "error");
  }
}

export function notify(type, title, text, callback) {
  Swal.fire(title, text, type).then(result => {
    if (result.value && callback) callback();
  });
}

export function questionAlert(text, callback) {
  Swal.fire({
    title: i18n.t("global:areYouSure"),
    text: text,
    type: "warning",
    showCancelButton: true,
    confirmButtonText: i18n.t("global:yes"),
    confirmButtonColor: "#ED8D6D",
    cancelButtonText: i18n.t("global:no"),
    cancelButtonColor: "#40a8e7"
  }).then(result => {
    if (result.value) callback();
  });
}

export function capitalize(s) {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function getValueInCurrentLang(value) {
  return value[i18n.language ? i18n.language : 'en'];
}
