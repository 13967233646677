import styled from "styled-components";
import { xlsIcon } from "../components/icons";

export const XlsLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: underline;
  color: #6B6F82;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 0.875rem;
  &:before {
    content: '';
    display: inline-block;
    width: 1rem;
    height: 1rem;
    background: url("${xlsIcon}") no-repeat;
    background-size: contain;
    margin-right: 0.65em
  }
`;