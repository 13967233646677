import * as React from "react";
import styled from "styled-components";

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1rem;
  color: #67676e;
  position: relative;
  padding-left: 1.25rem;
  &:before {
    position: absolute;
    left: 0;
    top: calc(50% - 0.5em);
    content: "";
    width: 1em;
    height: 1em;
    border: 2px solid #F05A28;
    box-sizing: border-box;
    border-radius: 50%;
    background: #fff;
    display: inline-block;
    cursor: pointer;
    -webkit-transition: all .25s ease;
    transition: all .25s ease;
  }
`;

const RadioButton = styled.div`
  display: flex;
  & input {
    display: none;
    &:checked {
      + ${RadioLabel} {
        &:before {
          background-color: #F05A28;
          box-shadow: inset 0 0 0 2px #fff;
        }
      }
    }
  }
`;

export const Radio = ({ ...props }) => {
  return (
    <RadioButton>
      <input type="radio" {...props} />
      <RadioLabel htmlFor={props.id}>{props.title}</RadioLabel>
    </RadioButton>
  );
};