import * as React from "react";
import styled from "styled-components";
import { withNamespaces } from "react-i18next";
import i18n from "../config/i18n";
import expand from "../resources/img/icons/expand_more.svg";

export const Options = styled.div`
  position: absolute;
  overflow-y: auto;
  top: 100%;
  width: 100%;
  max-height: 150px;
  background: rgba(255, 255, 255, 0.85);
  border: 1px solid #d6d7dc;
  padding: 0 0.5rem;
  border-top: 0;
  z-index: 10;
`;

export const Option = styled.button`
  font-weight: 300;
  font-size: 0.875em;
  line-height: normal;
  width: 100%;
  padding: 0.5rem 0.5rem;
  text-align: left;
  border: 0;
  background: transparent;
  cursor: pointer;
  &:hover {
    color: #67676e;
    font-weight: 400;
  }
  &:not(:first-of-type) {
    border-top: 1px solid #d6d7dc;
  }
`;

export const Value = styled.button`
  background: transparent;
  border: 0;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #67676e;
  border-radius: 2px;
  padding: 0.25rem 2.5rem 0.25rem 1rem;
  font-weight: 300;
  font-size: 0.875em;
  text-align: left;
  line-height: normal;
  color: #67676e;
  cursor: pointer;
`;

export const Select = styled.div`
  position: relative;
  background: url("${expand}") 90% 50% no-repeat;
  flex: 1;
  max-width: 200px;
  &:focus {
    outline: 0;
  }
  ${Options} {
    display: ${props => (props.active ? "block" : "none")};
  }
`;

class CustomSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
  }

  render() {
    const { t, value, placeholder, options, children, click } = this.props;
    const { show } = this.state;
    return (
      <Select
        tabIndex={0}
        active={show}
        onBlur={() => setTimeout(() => this.setState({ show: false }), 150)}
      >
        <Value
          onClick={() =>
            this.setState(prevState => ({ show: !prevState.show }))
          }
        >
          {value || placeholder}
        </Value>
        <Options>
          {options ? options.map((o, i) =>
            value !== (o.name ? o.name[i18n.language] : t(o)) ? (
              <Option
                key={i}
                onClick={() => {
                  click(o);
                  this.setState({ show: false });
                }}
              >
                {o.name ? o.name[i18n.language] : t(o)}
              </Option>
            ) : null
          ) : children}
        </Options>
      </Select>
    );
  }
}
export default withNamespaces("statuses")(CustomSelect);
