import * as React from "react";
import styled, { keyframes } from "styled-components";
import { FlexColumn, FlexRow, Column } from "../../components/flexboxes";
import { Paragraph, Subtitle } from "../../components/fonts";
import { BasicInput, Label } from "../../components/basicInput";
import { ActionButton } from "../../components/button";
import { Advangates } from "../../components/advantages";

const RestTest = styled(FlexRow)`
  & button {
    font-size: 0.75rem;
    padding: 0.5125rem;
    margin-left: 0.5rem;
  }
`;

const Code = styled.code`
  background: #2c303b;
  border-radius: 3px;
  font-weight: 300;
  min-height: 4.5rem;
  font-size: 0.75rem;
  line-height: normal;
  color: #ffffff;
  padding: 1rem;
  word-break: break-word;
`;

const cursorAnimation = keyframes`
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Cursor = styled.div `
  width: 4px;
  height: 0.75rem;
  background: #ffffff;
  animation: ${cursorAnimation} 2s linear infinite;
`;

export const Settings = ({
  t,
  application,
  check,
  inputChange,
  sendRequest,
  testingResult
}) => {
  return (
    <Column innerSpace="0.5rem">
      <Subtitle>{t("settings")}</Subtitle>
      <Paragraph dangerouslySetInnerHTML={{__html: t('info')}} />
      {/*Parcel info GET callback URL*/}
      <RestTest alignItems="flex-end">
        <BasicInput
          label={t("parcelInfoGet")}
          icon="link"
          name="infoUrl"
          value={application.infoUrl}
          type="text"
          onChange={inputChange}
          required
        />
        <ActionButton
          title={t("runTest")}
          icon="play_circle_outline"
          click={() => sendRequest("info")}
          size="small"
        />
      </RestTest>
      {/*Parcel status PUT callback URL*/}
      <RestTest alignItems="flex-end">
        <BasicInput
          label={t("parcelInfoPut")}
          icon="link"
          name="statusUrl"
          value={application.statusUrl}
          type="text"
          onChange={inputChange}
          required
        />
        <ActionButton
          title={t("runTest")}
          icon="play_circle_outline"
          click={() => sendRequest("status")}
          size="small"
        />
      </RestTest>
      {/*Acquiring payment link GET callback URL*/}
      {/*<RestTest alignItems="flex-end">*/}
        {/*<BasicInput*/}
          {/*label={t("acquiring")}*/}
          {/*icon="link"*/}
          {/*name="payUrl"*/}
          {/*value={application.payUrl}*/}
          {/*type="text"*/}
          {/*onChange={inputChange}*/}
        {/*/>*/}
        {/*<ActionButton*/}
          {/*title={t("runTest")}*/}
          {/*icon="play_circle_outline"*/}
          {/*click={() => sendRequest("payment")}*/}
        {/*/>*/}
      {/*</RestTest>*/}
      <FlexColumn>
        <Label>{t("sideChecks")}</Label>
        <Advangates
          items={[
            { title: t("parcelInfoGotCorrectly"), status: check.info },
            { title: t("parcelStatusSentCorrectly"), status: check.status }
            // { title: t("paymentLinkResponsded"), status: check.payment }
          ]}
        />
      </FlexColumn>
      <FlexColumn>
        <Label>{t("testingResults")}</Label>
        <Code>
          {typeof testingResult === "object" ? (
            <React.Fragment>
              <div>{"{"}</div>
              {Object.entries(testingResult).map((o, index) => (
                <div key={index} style={{ paddingLeft: "1em" }}>
                  "{o[0]}": "{o[1]}"
                  {index !== Object.keys(testingResult).length - 1 ? "," : ""}
                </div>
              ))}
              <div>{"}"}</div>
            </React.Fragment>
          ) : !testingResult ? (
            <Cursor />
          ) : (
            testingResult
          )}
        </Code>
      </FlexColumn>
    </Column>
  );
};
