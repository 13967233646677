import * as React from "react";
import { withNamespaces } from "react-i18next";
import Modal from "../../components/modal";
import { BasicInput } from "../../components/basicInput";
import { Flex } from "../../components/flexboxes";
import { ActionButton } from "../../components/button";
import {
  createDictionary,
  updateDictionary,
  createField,
  updateField,
} from "../../services/dictionary.service";
import { handleError } from "../../services/other.service";

class CreateUpdateDictionary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
      ru: "",
      kk: "",
      en: ""
    };
  }

  saveDictionary = e => {
    e.preventDefault();
    const { selected } = this.props;
    if (this.props.action === "edit") {
      const data = {
        kk: selected.dictionary.kk,
        ru: selected.dictionary.ru,
        en: selected.dictionary.en
      };
      updateDictionary(selected.dictionary.code, data)
        .then(() => this.props.close(true))
        .catch(err => handleError(err));
    } else {
      createDictionary(selected.dictionary)
        .then(() => this.props.close(true))
        .catch(err => handleError(err));
    }
  };

  saveField = e => {
    e.preventDefault();
    const { selected } = this.props;
    if (this.props.action === "edit") {
      const data = {
        kk: selected.field.kk,
        ru: selected.field.ru,
        en: selected.field.en
      };
      updateField(selected.dictionary.code, selected.field.key, data)
        .then(() => this.props.close(true))
        .catch(err => handleError(err));
    } else {
      createField(selected.dictionary.code, selected.field)
        .then(() => this.props.close(true))
        .catch(err => handleError(err));
    }
  };

  render() {
    const { t, type, action, close, selected, handleChange } = this.props;
    return (
      <Modal
        id="createDictionary"
        size="small"
        title={`${t(action)} ${t(type)}`}
        closeModal={() => close(null)}
      >
        <form onSubmit={this.save}>
          {Object.entries(selected[type]).map((d, index) => {
            if (action === "add") {
              return (
                <BasicInput
                  key={index}
                  label={t(d[0])}
                  name={d[0]}
                  value={d[1]}
                  type="text"
                  onChange={e => handleChange(e, type)}
                />
              );
            } else {
              return (
                  <BasicInput
                    key={index}
                    label={t(d[0])}
                    name={d[0]}
                    value={d[1]}
                    type="text"
                    onChange={e => handleChange(e, type)}
                    disabled={!(d[0] === "ru" || d[0] === "en" || d[0] === "kk")}
                  />
              );
            }
          })}
          <Flex justifyContent="flex-end" marginTop="2em">
            <ActionButton
              type="submit"
              title={action === "add" ? t("add") : t("save")}
              size="small"
              click={
                type === "dictionary" ? this.saveDictionary : this.saveField
              }
            />
          </Flex>
        </form>
      </Modal>
    );
  }
}

export default withNamespaces("dictionaries")(CreateUpdateDictionary);
