import ru from "./ru.json";
import en from "./en.json";
// import kk from './kk.json';
// import qq from './qq.json';

const locales = {
  ru: { ...ru },
  en: { ...en }
  // kk: { ...kk },
  // qq: { ...qq },
};

export default locales;
