import axios from "axios";

export function getUserApplications() {
  return axios.get(`/a/applications/current`);
}

export function sentRestRequest(type, application) {
  return axios.put(`/a/applications/${application.id}/${type}`, application);
}

export function uploadFile(type, id, data) {
  return axios.post(`/a/applications/${id}/${type}`, data, { headers: {
    'Content-Type': 'multipart/form-data'
  }});
}

export function getAllApplications() {
  return axios.get(`/a/applications`);
}

export function getApplication(id) {
  return axios.get(`/a/applications/${id}`);
}

export function approveOrDecline(id, type) {
  return axios.put(`/a/applications/${id}/${type}`, {});
}
