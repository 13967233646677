import { createStore } from "redux";

const initialState = {
  currentUser: {
    phone: "",
    system: ""
  },
  currentUserIsLoading: true,
  systems: []
};

function cabinetStore(state = initialState, action) {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        currentUser: action.payload
      };
    case "CURRENT_USER_LOADING":
      return {
        ...state,
        currentUserIsLoading: action.payload
      };
    case "LOADING":
      return {
        ...state,
        loading: action.payload
      };
    case "GET_SYSTEMS":
      return {
        ...state,
        systems: action.payload
      };
    default:
      return state;
  }
}

let store = createStore(cabinetStore);

// store.subscribe(() => console.log(store.getState()));
export default store;
