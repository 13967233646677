import * as React from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import MainLayout from "../layouts/main";
import { Title } from "../components/title.js";
import { Table, Tr, Th, Td } from "../components/flexibleTable.js";
import { ActionButton } from "../components/button.js";
import { Row, FlexRow } from "../components/flexboxes";
import { getSystems } from "../services/other.service";
import { Refresh } from "../components/button";
import SystemInfo from "./modals/systemInfo";
import { InformButton } from "../components/flexibleTable";

class Systems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      system: {
        name: props.t("all")
      },
      refreshing: false,
      modalType: "",
      currentSystem: {}
    };
    getSystems();
  }

  componentDidMount() {
    this.getsystemList();
  }

  getsystemList = () => {
    getSystems();
    this.setState({ refreshing: false });
    // getsystems(this.state.system.id)
    //   .then(res => this.setState({ systems: res.data.list, refreshing: false }))
    //   .catch(err => handleError(err));
  };

  refresh = () => {
    this.setState({ refreshing: true }, () => this.getsystemList());
  };

  closeModal = reload => {
    this.setState(
      {
        modalShow: false,
        currentSystem: {}
      },
      () => {
        if (reload === true) this.getsystemList();
      }
    );
  };

  render() {
    const { t, systems } = this.props;
    const { system, modalType, currentSystem } = this.state;
    const tableHeaders = [
      { title: t("system") },
      { title: t("actions.title") }
    ];
    return (
      <React.Fragment>
        <Row justifyContent="space-between" marginBottom="0.5rem">
          <Row justifyContent="flex-start" alignItems="center" fullWidth>
            <Title>{t("title")}</Title>
            <Refresh click={this.refresh} isLoading={this.state.refreshing} />
          </Row>
          <FlexRow justifyContent="flex-start" innerSpace="0.5rem">
            <ActionButton
              size="small"
              icon="person_add"
              title={t("actions.create")}
              click={() =>
                this.setState({ modalShow: true, modalType: "create" })
              }
            />
          </FlexRow>
        </Row>
        <Table
          fullWidth
          headers={tableHeaders.map((h, index) => (
            <Th key={index}>{h.title}</Th>
          ))}
        >
          {systems.length > 0 ? (
            systems.map((system, index) => (
              <Tr key={index}>
                {/*<Td>{systems.find(system => system.id === system.systemId).name}</Td>*/}
                <Td>
                  {systems[index].name}
                </Td>
                <Td>
                  <FlexRow justifyContent="center" innerSpace="0.25rem">
                    <InformButton
                      title={t("actions:seeInfo")}
                      onClick={() =>
                        this.setState({
                          modalShow: true,
                          modalType: "show",
                          currentSystem: system
                        })
                      }
                    >
                      i
                    </InformButton>
                      <InformButton
                        title={t("actions:edit")}
                        onClick={() =>
                          this.setState({
                            modalShow: true,
                            modalType: "edit",
                            currentSystem: system
                          })
                        }
                      >
                        <i className="material-icons">edit</i>
                      </InformButton>
                  </FlexRow>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={4}>{t("global:nothingFound")}</Td>
            </Tr>
          )}
        </Table>
        {this.state.modalShow ? (
          <SystemInfo
            close={this.closeModal}
            type={modalType}
            currentSystem={currentSystem}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({ systems: state.systems });

export default withNamespaces("system")(
  MainLayout(connect(mapStateToProps)(Systems))
);
