import * as React from "react";
import MainLayout from "../layouts/main";
import { withNamespaces } from "react-i18next";
import styled from "styled-components";
import { Title } from "../components/title.js";
import { Table, Tr, Td } from "../components/flexibleTable.js";
import { ActionButton } from "../components/button.js";
import { Roles } from "../components/status";
import { Row, FlexRow } from "../components/flexboxes";
import AddEmployee from "./modals/addEmployee";
import { getStaff } from "../services/staff.service";
import { handleError } from "../services/other.service";
import NumberFormat from "react-number-format";
import { phoneFormats } from "../resources/constants/phone-formats";
import { connect } from "react-redux";
import { Th } from "../components/flexibleTable";
import { Refresh } from "../components/button";

export const Action = styled.button`
  background: transparent;
  font-weight: 300;
  font-size: 0.875rem;
  padding: 0.25em 0.5em;
  border-radius: 10px;
  line-height: normal;
  text-align: center;
  color: #40a8e7;
  border: 1px solid transparent;
  cursor: pointer;
  &:hover {
    border: 1px solid #40a8e7;
  }
`;

class Staff extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      staff: [],
      chosenEmployee: null
    };
  }

  componentDidMount() {
    this.getStaffList();
  }

  getStaffList() {
    getStaff()
      .then(res => this.setState({ staff: res.data.list, refreshing: false }))
      .catch(err => handleError(err));
  }

  closeModal = reload => {
    this.setState({ modalShow: false, chosenEmployee: null }, () => {
      if (reload === true) this.getStaffList()
    })
  };

  refresh = () => {
    this.setState({ refreshing: true }, () => this.getStaffList());
  };

  render() {
    const { t, currentUser } = this.props;
    const { staff } = this.state;
    const tableHeaders = [
      { title: t("name"), align: "left" },
      { title: t("phone") },
      { title: t("role") },
      { title: t("actions.title") }
    ];
    return (
      <React.Fragment>
        <Row justifyContent="space-between" marginBottom="0.5rem">
          <Row justifyContent="flex-start" alignItems="center" fullWidth>
            <Title>{t("title")}</Title>
            <Refresh click={this.refresh} isLoading={this.state.refreshing} />
          </Row>
          <FlexRow justifyContent="flex-start" innerSpace="0.5rem">
            { ['OPERATOR', 'LOGISTICIAN'].includes(currentUser.role) ? (
              <ActionButton
                size="small"
                icon="person_add"
                click={() => this.setState({ modalShow: true })}
              />
            ) : null}
          </FlexRow>
        </Row>
        <Table
          fullWidth
          headers={tableHeaders.map((h, index) => (
            <Th key={index} textAlign={h.align}>
              {h.title}
            </Th>
          ))}
        >
          {staff.length > 0 ? (
            staff.map((s, index) => (
              <Tr key={index}>
                <Td textAlign={"left"}>
                  {s.firstname ? s.firstname + " " : ""}
                  {s.lastname ? s.lastname : ""}
                </Td>
                <Td>
                  <NumberFormat
                    value={s.phone}
                    displayType={"text"}
                    format={phoneFormats[s.phone.length.toString()]}
                  />
                </Td>
                <Td>
                  <Roles alignCenter role={s.role}>
                    {t(`roles.${s.role}`)}
                  </Roles>
                </Td>
                <Td>
                  <Action
                    onClick={() =>
                      this.setState({
                        chosenEmployee: s,
                        modalShow: true
                      })
                    }
                  >
                    {t("actions.edit")}
                  </Action>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={4}>{t("global:nothingFound")}</Td>
            </Tr>
          )}
        </Table>
        {this.state.modalShow ? (
          <AddEmployee
            chosenEmployee={this.state.chosenEmployee}
            close={this.closeModal}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({ currentUser: state.currentUser });

export default withNamespaces("staff")(
  MainLayout(connect(mapStateToProps)(Staff))
);
