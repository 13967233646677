import * as React from "react";
import styled from "styled-components";
import hexagons from "../resources/img/hexagons.svg";
import { DotLoader } from "../layouts/main/loader";

export const CardIcon = styled.img`
  height: 2em;
  width: 2em;
`;

export const CardDigit = styled.span`
  margin: 0.4em 0;
  font-size: 2rem;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  text-align: center;
`;
export const CardLabel = styled.span`
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 1rem;
  text-align: center;
`;

export const Info = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: opacity 0.25s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #969ec5;
  box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-weight: normal;
  line-height: 1.25rem;
  font-size: 0.875rem;
  text-align: center;
  color: #ffffff;
  padding: 2rem;
  z-index: 1;
`;

export const CardHover = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: normal;
  font-size: 0.875rem;
  background: transparent;
  color: #c2c2c3;
  border-radius: 50%;
  border: 2px solid #c2c2c3;
  transition: opacity 0.25s ease;
  z-index: 2;
  &:hover {
    opacity: 0;
    & + ${Info} {
      opacity: 1;
    }
  }
`;

export const CardBase = styled.div`
  position: relative;
  display: flex; 
  flex-flow: column;
  flex: 1 0 ${props => (props.size === "middle" ? "250px" : "200px;")}
  justify-content: space-between;
  align-items: center;
  background: ${props =>
    props.orange ? "#F05A28" : "#ffffff"} url("${hexagons}");
  background-size: contain;
  box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 1.5em;
  margin: 0.75rem;
  cursor: ${props => props.button ? "pointer" : "default"};
  max-width: ${props =>
    props.size === "small"
      ? "150px"
      : props.size === "middle"
      ? "250px"
      : "none"};
  ${CardDigit} {
    color: ${props => (props.orange ? "#ffffff" : "#F05A28")};
  }
  ${CardLabel} {
    color: ${props => (props.orange ? "#ffffff" : "#6B6F82")};
    font-size: ${props => (props.size === "small" ? "0.875rem" : "1rem")};
  }
`;

export const CardRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: -0.75em -0.75em 1em -0.75em;
`;

export const Card = ({ orange, size, icon, digit, label, info, click }) => {
  return (
    <CardBase orange={orange} size={size} onClick={click} button={click}>
      <CardIcon src={icon} alt="" />
      <CardDigit>
        {digit || digit === 0 || digit === "-" ? digit : <DotLoader />}
      </CardDigit>
      <CardLabel>{label}</CardLabel>
      <CardHover>?</CardHover>
      <Info>{info}</Info>
    </CardBase>
  );
};
