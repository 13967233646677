import styled from "styled-components";
import { Column, FlexRow } from "./flexboxes";


export const FaqRow = styled(FlexRow)`
  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;

export const Questions = styled(Column)`
  padding-right: 0 !important;
`;

export const Question = styled.div`
  font-weight: 300;
  font-size: 0.875rem;
  padding: 0.5em 1em 0.5em ${props => props.child ? "2rem" : ""};
  background: ${props => (props.active ? "#40A8E7" : "transparent")};
  line-height: normal;
  border: 0;
  text-align: left;
  cursor: pointer;
  color: ${props => (props.active ? "#FFF" : "#67676E")};
`;

export const Answers = styled(Column)`
  min-height: 50vh;
`;

export const Answer = styled.div`
  color: #333;
`;