import i18n from "../config/i18n";
import Swal from "sweetalert2";
import axios from "axios";
import store from "../config/store";
import { afterLogout } from "./authorization.service";


export function getAllSystems() {
    axios.get('/a/systems')
      .then(res =>
        store.dispatch({ type: "GET_SYSTEMS", payload: res.data.list })
      )
      .catch(err => handleError(err));
}

function _sessionExpiredError() {
    Swal
      .fire(i18n.t('global:error'), i18n.t('global:sessionExpired'), 'error')
      .then(() => afterLogout())
  }

export function handleError(error) {
    if (error === 'sessionExpired') {
      _sessionExpiredError()
    } else {
      let err = '';
      try {
        const message = JSON.parse(error.response.data.message);
        err = message[i18n.language] || message.ru;
      } catch (e) {
        err = i18n.t("global:unexpectedError");
      }
      Swal.fire(i18n.t("global:error"), err, "error");
    }
  }

export function createSystem(obj) {
    const data = {
        identifier: obj.identifier,
        name: obj.name,
        verified: obj.verified,
        basicIntegration: obj.basicIntegration,
        advancedIntegration: obj.advancedIntegration,
        infoUrl: obj.infoUrl,
        statusUrl: obj.statusUrl,
        callCenterNumber: obj.callCenterNumber,
        dropSms: obj.dropSms,
    };

    return axios.post('/a/systems/special', data);
}

export function changeSystem(systemId, obj) {
    const data = {
        identifier: obj.identifier,
        name: obj.name,
        verified: obj.verified,
        basicIntegration: obj.basicIntegration,
        advancedIntegration: obj.advancedIntegration,
        infoUrl: obj.infoUrl,
        statusUrl: obj.statusUrl,
        callCenterNumber: obj.callCenterNumber,
        dropSms: obj.dropSms,
    };

    return axios.put(`/a/systems/${systemId}/special`, data);
}