export const features = [
  {
    title: "findAndGetInfo",
    without: true,
    basic: true,
    advanced: true
  },
  { title: "dropParcels", without: true, basic: true, advanced: true },
  {
    title: "withdrawParcels",
    without: true,
    basic: true,
    advanced: true
  },
  {
    title: "smsNotifications",
    without: true,
    basic: true,
    advanced: true
  },
  {
    title: "parcelTracking",
    without: true,
    basic: true,
    advanced: true
  },
  {
    title: "addStaff",
    without: false,
    basic: true,
    advanced: true
  },
  {
    title: "paymentOnDelivery",
    without: false,
    basic: true,
    advanced: true
  },
  {
    title: "forehandBooking",
    without: false,
    basic: true,
    advanced: true
  },
  {
    title: "connectToYourDb",
    without: false,
    basic: true,
    advanced: true
  },
  // {
  //   title: "developYourOwnApps",
  //   without: false,
  //   basic: false,
  //   advanced: true
  // },
  {
    title: "fullAccessTo",
    without: [{ icon: "phone_iphone", text: "pickCellMobileApp" }],
    basic: [
      { icon: "phone_iphone", text: "pickCellMobileApp" },
      { icon: "web", text: "web-interface" },
      { icon: "phone_iphone", text: "courierMobileApp" },
      { icon: "code", text: "apiBasic" }
    ],
    advanced: [
      { icon: "phone_iphone", text: "pickCellMobileApp" },
      { icon: "web", text: "web-interface" },
      { icon: "phone_iphone", text: "courierMobileApp" },
      { icon: "code", text: "apiFull" }
    ]
  },
  {
    title: "actions",
    without: true,
    basic: true,
    advanced: true
  }
];