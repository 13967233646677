import * as React from "react";
import Chart from "react-apexcharts";

class BarChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        colors: ["#f05a28"],
        chart: {
          id: "basic-bar",
          events: {
            click: () => this.props.swapChart("lineChart")
          }
        },
        xaxis: {
          categories: [],
          axisBorder: {
            show: false
          },
          title: {
            text: 'Часы'
          }
        },
        yaxis: {
          title: {
            text: 'Длительность простоя (мин.)'
          }
        }
      },
      series: [
        {
          data: []
        }
      ]
    };
  }

  componentDidMount() {
    this.reload();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.series !== this.props.series) {
      this.reload();
    }
  }

  reload = () => {
    const newSeries = [],
      data = this.props.series;
    newSeries.push({ data, name: "Длительность простоя (мин.):" });

    this.setState({
      series: newSeries,
      options: {
        ...this.state.options,
        xaxis: {
          ...this.state.options.xaxis,
          categories: this.props.labels
        }
      }
    });
  };

  render() {
    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        type="bar"
        height="300"
      />
    );
  }
}

export default BarChart;
