import axios from "axios";

export function getLockers(page, perPage, region, status, searchKey) {
  return axios.get(
    `/a/statistics/lockers?region=${region}&limit=${perPage}&page=${page}${
      status && status !== "ALL" ? `&status=${status}` : ""
    }${searchKey ? `&searchKey=${searchKey}` : ""}`
  );
}

export function downloadLockersXls(region, status) {
  return axios({
    url: `/a/statistics/lockers/download?region=${region}${
      status && status !== "ALL" ? `&status=${status}` : ""
    }`,
    method: "GET",
    responseType: "blob"
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "lockers-report.xlsx");
    document.body.appendChild(link);
    link.click();
    link.remove();
  });
}

export function getLockerById(id) {
  return axios.get(`/a/lockers/${id}`);
}

export function createTastamat(data) {
  return axios.post(`/a/lockers/create`, data);
}

export function updateTastamat(data) {
  return axios.put(`/a/lockers/update`, data);
}

export function lockerAction(id, type) {
  if (type !== "nothing") return axios.put(`/a/lockers/${id}/${type}`, "");
}

export function getLockersStatistics() {
  return axios.get(`/a/statistics/lockers-status`);
}

export function getLockerActionsStatistics() {
  return axios.get(`/a/statistics/lockers-action-status?actionStatus=false`);
}

export function getListByAction(action) {
  return axios.get(`/a/statistics/lockers?actionStatus=false&action=${action}`);
}

export function getLockerRatings(id, page) {
  return axios.get(`/a/lockers/${id}/ratings?limit=3&page=${page}`);
}

export function sendLockerReview(id, rating, comment) {
  return axios.put(`/a/lockers/rate`, {
    identifier: id,
    rating: rating,
    comment: comment
  });
}

export function getStatsByLockerId(id, from, to) {
  return axios.get(`/a/statistics/lockers-bar-chart/?from=${from}&to=${to}&locker=${id}`)
}

export function getStatsLifecycle(id, from, to) {
  return axios.get(`/a/statistics/lockers-line-chart/?from=${from}&to=${to}&locker=${id}`)
}
