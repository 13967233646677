import axios from "axios";
import moment from "moment";

export function getDebts(limit, page, systemId) {
  return axios.get(
    `/a/orders/system-debt?limit=${limit}&page=${page}${systemId ? `&system=${systemId}` : ""}`
  );
}
export function getTariffs(systemId) {
  return axios.get(`/a/tariffs${systemId ? `?system=${systemId}` : ""}`);
}
export async function createTariff(data) {
  data.startDate = Date.now();
  // data.startDate = await moment(data.startDate).valueOf();
  // data.endDate = await moment(data.endDate).valueOf();
  return axios.post(`/a/tariffs`, data);
}

export async function changeTariff(data) {
  data.startDate = Date.now();
  // data.endDate = await moment(data.endDate).valueOf();
  return axios.put(`/a/tariffs`, data);
}

export function removeTariff(systemId, id) {
  return axios.delete(`/a/tariffs?system=${systemId}&id=${id}`);
}

export function downloadDebtXls(systemId) {
  return axios({
    url: `/a/orders/system-debt/download${systemId ? `?system=${systemId}` : ""}`,
    method: "GET",
    responseType: "blob"
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "debt-report.xlsx");
    document.body.appendChild(link);
    link.click();
    link.remove();
  });
}
