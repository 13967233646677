import axios from "axios";

export function getDictionaries() {
  return axios.get('/a/dictionaries');
}

export function createDictionary(data) {
  return axios.post(`/a/dictionaries/`, data);
}

export function updateDictionary(code, data) {
  return axios.put(`/a/dictionaries/${code}`, data);
}

export function deleteDictionary(code) {
  return axios.delete(`/a/dictionaries/${code}`);
}

export function getFields(code) {
  return axios.get(`/a/dictionaries/${code}/fields`)
}

export function createField(code, data) {
  return axios.post(`/a/dictionaries/${code}/fields`, data)
}

export function updateField(code, fieldId, data) {
  return axios.put(`/a/dictionaries/${code}/fields/${fieldId}`, data)
}

export function deleteField(code, key) {
  return axios.delete(`/a/dictionaries/${code}/fields/${key}`)
}