import * as React from "react";
import { withNamespaces } from "react-i18next";
import moment from "moment";
import { connect } from "react-redux";
import MainLayout from "../layouts/main";
import { Title } from "../components/title.js";
import { Table, Tr, Th, Td } from "../components/flexibleTable.js";
import { Row, FlexRow } from "../components/flexboxes";
import { getSystems, handleError } from "../services/other.service";
import { Refresh } from "../components/button";
import { getDebts, downloadDebtXls } from "../services/billing.service";
import CustomSelect, { Option } from "../components/customSelect";
import { XlsLink } from "../components/xlsLink";
import { Status } from "../components/status";
import Pagination from "../components/pagination";

class Billing extends React.Component {
  user = this.props.cookies.get("user") || this.props.currentUser || {};
  constructor(props) {
    super(props);
    this.state = {
      billing: [],
      system: {
        name: props.t("all")
      },
      ordersCount: 0,
      page: 0,
      perPage: props.cookies.get("tableRowsPerPage") || 10,
      refreshing: false
    };
    getSystems();
    console.log(this.user);
  }

  componentDidMount() {
    this.getDebts();
  }

  getDebts = () => {
    const { page, perPage, system } = this.state;
    getDebts(
      perPage,
      page,
      this.user.role === "ADMIN" ? system.id : this.user.system && this.user.system.id
    )
      .then(res =>
        this.setState({
          billing: res.data.list,
          ordersCount: res.data.count,
          refreshing: false
        })
      )
      .catch(err => handleError(err));
  };

  onPageChanged = data => {
    const { currentPage } = data;
    this.setState({ page: currentPage - 1 }, () => this.getDebts());
  };

  refresh = () => {
    this.setState({ refreshing: true }, () => this.getDebts());
  };

  render() {
    const { t, systems } = this.props;
    const { billing, system, ordersCount, page, perPage } = this.state;
    const role = this.user.role;
    const tableHeaders = [
      { title: t("trackNumber") },
      { title: t("status") },
      { title: t("startDate") },
      { title: t("endDate") },
      { title: t("debt") }
    ];
    return (
      <React.Fragment>
        <Row
          justifyContent="flex-start"
          alignItems="center"
          marginBottom="0.5rem"
          fullWidth
        >
          <Title>{t("title")}</Title>
          <Refresh click={this.refresh} isLoading={this.state.refreshing} />
        </Row>
        {role === "ADMIN" ? (
          <FlexRow
            innerSpace="1rem"
            alignItems="center"
            marginBottom="1rem"
            fullWidth
            wrapItems
          >
            <CustomSelect value={system.name || system.id}>
              <Option
                onClick={() =>
                  this.setState({ system: { name: t("all") } }, () =>
                    this.getDebts()
                  )
                }
              >
                {t("all")}
              </Option>
              {systems.map((s, i) => (
                <Option
                  key={i}
                  onClick={() =>
                    this.setState({ system: s }, () => this.getDebts())
                  }
                >
                  {s.name || s.id}
                </Option>
              ))}
            </CustomSelect>
            <XlsLink onClick={() => downloadDebtXls(system.id)}>
              {t("global:downloadSheet")}
            </XlsLink>
          </FlexRow>
        ) : null}
        <Row justifyContent="flex-end" marginBottom="0.5em" fullWidth>
          <Pagination
            totalRecords={ordersCount}
            currentPage={page + 1}
            pageLimit={perPage}
            pageNeighbours={1}
            onPageChanged={this.onPageChanged}
          />
        </Row>
        <Table
          fullWidth
          headers={tableHeaders.map((h, index) => (
            <Th key={index}>{h.title}</Th>
          ))}
        >
          {billing.length > 0 ? (
            billing.map((b, index) => (
              <Tr key={index}>
                <Td>{b.trackNumber}</Td>
                <Td>
                  <Status type={b.status}>
                    {t(`statuses:${b.status}`)}
                    &nbsp;
                    {b.isCourier ? t("byCourier") : ""}
                  </Status>
                </Td>
                <Td>{moment(b.startDate).format("DD.MM.YY")}</Td>
                <Td>{moment(b.endDate).format("DD.MM.YY")}</Td>
                <Td>{b.debt}</Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={5}>{t("global:nothingFound")}</Td>
            </Tr>
          )}
        </Table>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser,
  systems: state.systems
});

export default withNamespaces("billing")(
  MainLayout(connect(mapStateToProps)(Billing))
);
