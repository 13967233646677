import React, { Component } from "react";
import styled from "styled-components";

const PaginationBase = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const Page = styled.button`
  border: 0;
  background: transparent;
  color: ${props => props.active ? '#F15A29' : '#565656'};
  font-size: 1em;
  font-weight: bold;
  border-bottom: 1px solid transparent;
  border-color: ${props => props.active ? '#F15A29' : 'transparent'};
  &:not(:first-of-type) {
    margin-left: 0.5em;
  }
  &:hover {
    border-color: #F15A29;
  }
`;

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

class Pagination extends Component {
  totalPages = 0;

  constructor(props) {
    super(props);
    const { pageLimit, currentPage, pageNeighbours } = props;
    this.state = {
      pageLimit: parseInt(pageLimit),
      pageNeighbours: typeof pageNeighbours === "number" ? Math.max(0, Math.min(pageNeighbours, 2)) : 0,
      currentPage: currentPage
    };
  }

  gotoPage = page => {
    const { onPageChanged = f => f } = this.props;
    const { totalRecords, pageLimit } = this.props;

    const currentPage = Math.max(0, Math.min(page, Math.ceil(totalRecords / pageLimit)));

    const paginationData = {
      currentPage,
      totalPages: Math.ceil(totalRecords / pageLimit),
      pageLimit: pageLimit,
      totalRecords: totalRecords
    };

    this.setState({ currentPage }, () => onPageChanged(paginationData));
  };

  handleClick = (page, evt) => {
    evt.preventDefault();
    this.gotoPage(page);
  };

  handleMoveLeft = evt => {
    evt.preventDefault();
    this.gotoPage(this.state.currentPage - this.state.pageNeighbours * 2 - 1);
  };

  handleMoveRight = evt => {
    evt.preventDefault();
    this.gotoPage(this.state.currentPage + this.state.pageNeighbours * 2 + 1);
  };

  fetchPageNumbers = () => {
    const totalPages = Math.ceil(this.props.totalRecords / this.state.pageLimit);
    const currentPage = this.state.currentPage;
    const pageNeighbours = this.state.pageNeighbours;

    const totalNumbers = this.state.pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      let pages = [];

      const leftBound = currentPage - pageNeighbours;
      const rightBound = currentPage + pageNeighbours;
      const beforeLastPage = totalPages - 1;

      const startPage = leftBound > 2 ? leftBound : 2;
      const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

      pages = range(startPage, endPage);

      const pagesCount = pages.length;
      const singleSpillOffset = totalNumbers - pagesCount - 1;

      const leftSpill = startPage > 2;
      const rightSpill = endPage < beforeLastPage;

      const leftSpillPage = LEFT_PAGE;
      const rightSpillPage = RIGHT_PAGE;

      if (leftSpill && !rightSpill) {
        const extraPages = range(startPage - singleSpillOffset, startPage - 1);
        pages = [leftSpillPage, ...extraPages, ...pages];
      } else if (!leftSpill && rightSpill) {
        const extraPages = range(endPage + 1, endPage + singleSpillOffset);
        pages = [...pages, ...extraPages, rightSpillPage];
      } else if (leftSpill && rightSpill) {
        pages = [leftSpillPage, ...pages, rightSpillPage];
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };

  render() {
    this.totalPages = Math.ceil(this.props.totalRecords / this.props.pageLimit);
    if (!this.props.totalRecords || this.props.totalRecords === 1) return null;

    if (this.totalPages === 1) return null;

    const { currentPage } = this.props;
    const pages = this.fetchPageNumbers();
    return (
      <PaginationBase>
        {pages.map((page, index) => {
          if (page === LEFT_PAGE)
            return (
              <Page key={index} aria-label="Previous" onClick={this.handleMoveLeft}>
                &lt;
              </Page>
            );

          if (page === RIGHT_PAGE)
            return (
              <Page key={index} aria-label="Next" onClick={this.handleMoveRight}>
                &gt;
              </Page>
            );

          return (
            <Page key={index} active={currentPage === page}
                    onClick={e => this.handleClick(page, e)}
            >
              {page}
            </Page>
          );
        })}
      </PaginationBase>
    );
  }
}

export default Pagination;