import * as React from "react";
import styled from "styled-components";
import { withNamespaces } from "react-i18next";
import { Title } from "../../components/title";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { Table, Td, Th, Tr } from "../../components/flexibleTable";
import { ActionButton } from "../../components/button";
import { StatusSmall } from "../../components/status";
import { features } from "../../resources/constants/features";
import { getUserApplications } from "../../services/applications.service";

const Feature = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  &:not(:first-of-type) {
    margin-top: 0.5em;
  }
  > i {
    font-size: 1.125rem;
    margin-right: 0.25rem;
  }
`;

const PlanStatus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Status = ({ active }) => {
  return active ? (
    <i className="material-icons" style={{ color: "#208C25" }}>
      check
    </i>
  ) : null;
};

class AvailablePlans extends React.Component {
  connectBasicIntegration = () => {
    getUserApplications()
      .then(res =>
        this.props.history.push({
          pathname: "/settings/basic-integration",
          state: { application: res.data }
        })
      )
      .catch(err => console.log(err));
  };

  downloadApp = () => {
    Swal.fire({
      title: this.props.t("whichOS"),
      type: "info",
      showCancelButton: true,
      confirmButtonText: "Android",
      confirmButtonColor: "#a4c639",
      cancelButtonText: "iOS",
      cancelButtonColor: "#5856d6",
      reverseButtons: true
    }).then(result => {
      if (result.value) {
        window.open(
          "https://play.google.com/store/apps/details?id=kz.toolpar.tastamat",
          "_blank"
        );
      } else if (result.dismiss === "cancel") {
        window.open(
          "https://itunes.apple.com/kz/app/tastamat/id1455984098?mt=8",
          "_blank"
        );
      }
    });
  };

  render() {
    const { t, currentUser } = this.props;
    const tableHeaders = [
      { title: t("features"), align: "left" },
      {
        title: (
          <PlanStatus>
            {t("noIntegration")}
            <StatusSmall type="ready">{t("ready")}</StatusSmall>
          </PlanStatus>
        )
      },
      {
        title: (
          <PlanStatus>
            {t("basicIntegration")}
            {currentUser.system && currentUser.system.basicIntegration ? (
              <StatusSmall type="ready">{t("ready")}</StatusSmall>
            ) : null}
          </PlanStatus>
        )
      }
      // {
      //   title: (
      //     <PlanStatus>
      //       {t("advancedIntegration")}
      //       {currentUser.system &&
      //       currentUser.system.advancedIntegration ? (
      //         <StatusSmall type="ready">{t("ready")}</StatusSmall>
      //       ) : null}
      //     </PlanStatus>
      //   )
      // }
    ];
    return (
      <React.Fragment>
        <Title marginBottom="1.4rem">{t("setUpYourPlan")}</Title>
        {currentUser ? (
          <Table
            size="small"
            fullWidth
            headers={tableHeaders.map((h, index) => (
              <Th key={index} textAlign={h.align}>
                {h.title}
              </Th>
            ))}
          >
            {features.map((feature, index) => (
              <Tr key={index}>
                <Td textAlign="left">{t(feature.title)}</Td>
                <Td
                  verticalAlign={feature.title === "fullAccessTo" ? "top" : ""}
                >
                  {feature.title === "fullAccessTo" ? (
                    feature.without.map((f, index) => (
                      <Feature key={index}>
                        <i className="material-icons">{f.icon}</i>
                        {t(f.text)}
                      </Feature>
                    ))
                  ) : feature.title === "actions" ? (
                    <ActionButton
                      size="small"
                      icon="phone_iphone"
                      title={t("actions.downloadApp")}
                      click={this.downloadApp}
                    />
                  ) : (
                    <Status active={feature.without} />
                  )}
                </Td>
                <Td
                  verticalAlign={feature.title === "fullAccessTo" ? "top" : ""}
                >
                  {feature.title === "fullAccessTo" ? (
                    feature.basic.map((f, index) => (
                      <Feature key={index}>
                        <i className="material-icons">{f.icon}</i>
                        {t(f.text)}
                      </Feature>
                    ))
                  ) : feature.title === "actions" ? (
                    <ActionButton
                      size="small"
                      icon="settings"
                      title={t("actions.getAccess")}
                      // disabled={currentUser.system.basicIntegration}
                      click={() =>
                        this.props.history.push("/settings/basic-integration")
                      }
                    />
                  ) : (
                    <Status active={feature.basic} />
                  )}
                </Td>
                {/*<Td*/}
                {/*verticalAlign={feature.title === "fullAccessTo" ? "top" : ""}*/}
                {/*>*/}
                {/*{feature.title === "fullAccessTo" ? (*/}
                {/*feature.advanced.map((f, index) => (*/}
                {/*<Feature key={index}>*/}
                {/*<i className="material-icons">{f.icon}</i>*/}
                {/*{t(f.text)}*/}
                {/*</Feature>*/}
                {/*))*/}
                {/*) : feature.title === "actions" ? (*/}
                {/*<ActionButton*/}
                {/*size="small"*/}
                {/*icon="vpn_key"*/}
                {/*title={t("actions.getAccess")}*/}
                {/*click={() => console.log("clicked!")}*/}
                {/*disabled={currentUser.system && !currentUser.system.basicIntegration}*/}
                {/*/>*/}
                {/*) : (*/}
                {/*<Status active={feature.advanced} />*/}
                {/*)}*/}
                {/*</Td>*/}
              </Tr>
            ))}
          </Table>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser
});

export default withRouter(
  withNamespaces("settings")(connect(mapStateToProps)(AvailablePlans))
);
