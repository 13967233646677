export const initialLocker = {
  params: {
    code: '',
    index: '',
    oldIndex: '',
    size: '',
    rows: '',
    columns: '',
    presenceCode: '',
    gisUrl: '',
  },
  location: {
    type: '',
    access: '',
    latitude: '',
    longitude: '',
    descriptionRu: '',
    descriptionEn: '',
    descriptionKk: '',
    descriptionQq: '',
    time: '',
  },
  address: {
    regionCode: '',
    regionKk: '',
    regionQq: '',
    regionRu: '',
    regionEn: '',
    districtKk: '',
    districtQq: '',
    districtRu: '',
    districtEn: '',
    streetKk: '',
    streetQq: '',
    streetRu: '',
    streetEn: '',
    streetShortKk: '',
    streetShortQq: '',
    streetShortRu: '',
    streetShortEn: '',
    buildingIndex: '',
    buildingNumber: '',
    buildingKk: '',
    buildingQq: '',
    buildingRu: '',
    buildingEn: '',
    buildingShortKk: '',
    buildingShortQq: '',
    buildingShortRu: '',
    buildingShortEn: ''
  },
  partner: {
    bin: '',
    name: ''
  },
  schedule: {
    scheduleMonday: '',
    scheduleTuesday: '',
    scheduleWednesday: '',
    scheduleThursday: '',
    scheduleFriday: '',
    scheduleSaturday: '',
    scheduleSunday: '',
  },
  scheduleFromTo: {
    "scheduleMonday,From": '00:00',
    "scheduleTuesday,From": '00:00',
    "scheduleWednesday,From": '00:00',
    "scheduleThursday,From": '00:00',
    "scheduleFriday,From": '00:00',
    "scheduleSaturday,From": '00:00',
    "scheduleSunday,From": '00:00',
    "scheduleMonday,To": '00:00',
    "scheduleTuesday,To": '00:00',
    "scheduleWednesday,To": '00:00',
    "scheduleThursday,To": '00:00',
    "scheduleFriday,To": '00:00',
    "scheduleSaturday,To": '00:00',
    "scheduleSunday,To": '00:00',
  }
};
