import * as React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { Title } from "../../components/title";
import { ContentCard } from "../../components/contentCard";
import { FlexRow } from "../../components/flexboxes";
import {
  getUserApplications,
  sentRestRequest,
  uploadFile
} from "../../services/applications.service";
import { Settings } from "./basicIntegration_settings";
import { FillCompanyInfo } from "./basicIntegration_fillCompanyInfo";
import { handleError } from "../../services/other.service";

const RowWithLoading = styled(FlexRow)`
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity 1s ease;
  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;

class BasicIntegration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contentLoaded: false,
      application: {
        identificator: "",
        name: "",
        infoUrl: "",
        statusUrl: "",
        payUrl: "",
        document: {},
        logo: {}
      },
      check: {
        info: "",
        status: "",
        payment: ""
      },
      testingResult: ""
    };
  }

  componentDidMount() {
    getUserApplications()
      .then(res => {
        this.setState(
          {
            application: { ...this.state.application, ...res.data }
          },
          () => {
            this.setState({
              contentLoaded: true,
              check: {
                info: this.getRequestValue("PARCEL_INFO", "status"),
                status: this.getRequestValue("PARCEL_STATUS", "status"),
                payment: this.getRequestValue("PARCEL_PAYMENT", "status")
              }
            });
          }
        );
      })
      .catch(err => {
        handleError(err);
        this.props.history.push("/settings/available-plans");
      });
  }

  getRequestValue = (type, param) => {
    const { application } = this.state;
    if (application.requests && application.requests.find(x => x.type === type))
      return application.requests.find(x => x.type === type)[param];
    else return "";
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
      application: {
        ...this.state.application,
        [name]: value
      }
    });
  };

  handleCheckboxChange = e => {
    const { name } = e.target;
    this.setState(prevState => ({
      [name]: !prevState[name]
    }));
  };

  sendRequest = type => {
    sentRestRequest(type, this.state.application)
      .then(res => {
        if (type === "save") {
          this.props.history.push("/settings/available-plans");
          return;
        }
        let testingResult = res.data.response;
        // try {
        //   testingResult = JSON.parse(res.data.response);
        // } catch (e) {}
        this.setState({
          testingResult: testingResult,
          check: { ...this.state.check, [type]: res.data.status }
        });
      })
      .catch(err => handleError(err));
  };

  uploadFile = (type, files) => {
    const data = new FormData();
    data.append("file", files[0]);
    data.append("name", files[0].name);

    uploadFile(type, this.state.application.id, data)
      .then(res =>
        this.setState({
          application: {
            ...this.state.application,
            document:
              type === "documents" ? res.data : this.state.application.document,
            logo: type === "logos" ? res.data : this.state.application.logo
          }
        })
      )
      .catch(err => handleError(err));
  };

  render() {
    const { t } = this.props;
    const { allInfoIsCorrect, settingsWorksCorrectly } = this.state;
    return (
      <React.Fragment>
        <Title marginBottom="1.4rem">
          {t("settings:basicIntegration")}
        </Title>
        <ContentCard>
          <RowWithLoading show={this.state.contentLoaded}>
            <Settings
              t={t}
              application={this.state.application}
              check={this.state.check}
              inputChange={this.handleChange}
              sendRequest={this.sendRequest}
              testingResult={this.state.testingResult}
            />
            <FillCompanyInfo
              t={t}
              application={this.state.application}
              allInfoIsCorrect={allInfoIsCorrect}
              settingsWorksCorrectly={settingsWorksCorrectly}
              inputChange={this.handleChange}
              uploadFile={this.uploadFile}
              checkboxChange={this.handleCheckboxChange}
              sendRequest={this.sendRequest}
            />
          </RowWithLoading>
        </ContentCard>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({ currentUser: state.currentUser });

export default withRouter(
  withNamespaces("basicIntegration")(connect(mapStateToProps)(BasicIntegration))
);
