import * as React from "react";
import { connect } from "react-redux";
import { withCookies } from "react-cookie";
import styled from "styled-components";
import { Container } from "./container";
import Navbar from "./navbar.js";
import Sidebar, {SidebarBase} from "./sidebar.js";
import Footer from "./footer";
import { Loader } from "./loader.js";

const Content = styled.div`
  &.showSidebar {
    ${SidebarBase} {
      transform: translateX(0);
    }
  }
`;

const mapStateToProps = state => ({ loading: state.loading });

const MainLayout = Component => {
  return withCookies(
    connect(mapStateToProps)(
      class extends React.Component {
        render() {
          const { loading } = this.props;
          return (
            <Content id="content">
              <Navbar showMenu={true}/>
              <Sidebar />
              <Container>
                <Component {...this.props} />
              </Container>
              <Footer />
              {loading ? <Loader /> : null}
            </Content>
          );
        }
      }
    )
  );
};

export default MainLayout;
