import React, {useRef, useState} from "react";
import styled, {keyframes} from "styled-components";
import NumberFormat from "react-number-format";
import arrowDown from "../resources/img/icons/arrow-down.svg";
import i18n from "../config/i18n";
import { getValueInCurrentLang } from "../services/other.service";

export const Label = styled.label`
  width: 100%;
  font-size: 0.75rem;
  line-height: normal;
  padding: 0;
  color: #67676e;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.5em;
`;

export const FileLabel = styled.label`
  display: block;
  width: 100%;
  background: #ffffff;
  border: 2px solid #d6d7dc;
  border-radius: 2px;
  box-sizing: border-box;
  min-height: 34px;
  padding: 0.5rem 1rem 0.5rem 2.5rem;
`;

const InputBase = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  input[type="time"]::-webkit-calendar-picker-indicator {
    background: url("${arrowDown}") no-repeat center;
  }
  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
  & i {
    &:first-of-type {
      position: absolute;
      top: calc(50% - 0.4375em);
      left: 1rem;
      font-size: 0.875rem;
      color: #67676e;
    }
    &:nth-of-type(2) {
      position: absolute;
      top: calc(50% - 0.4375em);
      right: 1rem;
      font-size: 0.875rem;
      color: #67676e;
      cursor: pointer;
    }
  }
`;

const InputFileBase = styled(Label)`
  width: 100%;
  & ${FileLabel} {
    font-size: 0.75rem;
  }
  & input {
    display: none;
  }
`;

const PhoneInputBase = styled(InputBase)`
  & input {
    width: 100%;
    background: #ffffff;
    border: 2px solid #d6d7dc;
    border-radius: 2px;
    padding: 0.5rem 1rem 0.5rem 2.5rem;
    font-weight: 300;
    font-size: 0.75em;
    line-height: normal;
    color: #67676e;
  }
`;

const Input = styled.input`
  width: 100%;
  background: ${p => p.disabled ? '#f5f5f5' : '#ffffff'};
  border: 2px solid #d6d7dc;
  border-radius: 2px;
  padding: ${props => props.iconCount === 2
    ? '0.5rem 2.5rem'
    : props.iconCount === 1 || props.withIcon
      ? '0.5rem 1rem 0.5rem 2.5rem'
      : '0.5rem 1rem'
  };
  font-weight: 300;
  font-size: 0.75em;
  line-height: normal;
  color: #67676e;
`;

const Select = styled.select`
  padding-right: 2rem;
  background: #FFF url("${arrowDown}") no-repeat calc(100% - 12px) center;
  -webkit-appearance: none;
  -moz-appearance: none;
  &::-ms-expand {
    display: none;
  }
`;

const InputParent = styled.div`
  position: relative;
`;

const DateInput = styled(Input)`
  appearance: none;
  -webkit-appearance: none;
  display: inline-block !important;
  visibility: visible !important;
  &::-webkit-clear-button {
    display: none;
  }
  &::-webkit-inner-spin-button {
    display: none;
  }
  &::-webkit-calendar-picker-indicator {
    color: #2c3e50;
  }
`;

const fadeIn = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`;

const Info = styled.span`
  position: absolute;
  right: 0.25em;
  top: calc(50% - 1.2em);
  font-size: 0.75rem;
  line-height: 1em;
  background: #67676e;
  color: #fff;
  padding: 0.5rem;
  animation: ${fadeIn} 250ms linear;
`;

export const BasicInput = ({ label, icon, required, canCopyToClipboard, length, ...rest }) => {
  const [copied, setCopied] = useState(false);
  const inputRef = useRef(null);

  const copyToClipboard = () => {
    inputRef.current.disabled = false;
    inputRef.current.select();
    document.execCommand('copy');
    setCopied(true);
    inputRef.current.disabled = true;
    unselectInput();
    setTimeout(() => setCopied(false), 1500);
  };

  const unselectInput = () => {
    if (window.getSelection) {
      if (window.getSelection().empty) {  // Chrome
        window.getSelection().empty();
      } else if (window.getSelection().removeAllRanges) {  // Firefox
        window.getSelection().removeAllRanges();
      }
    } else if (document.selection) {  // IE?
      document.selection.empty();
    }
  }

  return (
    <InputBase>
      {label ? (
        <Label>{label} {required ? "*" : null}</Label>
      ) : null}
      <InputParent>
        {icon ? <i className="material-icons">{icon}</i> : null}
        <Input
          maxLength={length}
          {...rest}
          ref={inputRef}
          iconCount={(canCopyToClipboard && !!icon) ? 2
            : (canCopyToClipboard || !!icon) ? 1 : 0
          }
        />
        { canCopyToClipboard ?
          <i
            className="material-icons"
            onClick={copyToClipboard}
            title={i18n.t('global:copyToClipboard')}
          >
            content_copy
          </i>
          : null
        }
        { copied ? <Info>{i18n.t('global:copied')}</Info> : null }
      </InputParent>
    </InputBase>
  );
};

export const BasicSelect = ({ label, icon, placeholder, options, ...rest }) => {
  return (
    <InputBase>
      {label ? <Label>{label}</Label> : null}
      <InputParent>
        {icon ? <i className="material-icons">{icon}</i> : null}
        <Input as={Select} withIcon={!!icon} {...rest}>
          <option value={null}>{placeholder}</option>
          {options &&
            options.map((o, index) =>
              o.value && o.label ? (
                <option key={index} value={o.value}>
                  {o.label}
                </option>
              ) : o.id ? (
                <option key={index} value={o.id}>
                  {o.name || o.id}
                </option>
              ) : o.code ? (
                <option key={index} value={o.code}>
                  {getValueInCurrentLang(o)}
                </option>
              ) : (
                <option key={index} value={o}>
                  {o}
                </option>
              )
            )}
        </Input>
      </InputParent>
    </InputBase>
  );
};

export const BasicInputFile = ({
  id,
  label,
  icon,
  value,
  required,
  fileTypes,
  ...rest
}) => {
  return (
    <InputBase as={InputFileBase}>
      <Label>{label} {required ? "*" : null}</Label>
      <InputParent>
        {icon ? <i className="material-icons">{icon}</i> : null}
        <FileLabel htmlFor={id}>{value}</FileLabel>
        <Input
          type="file"
          id={id}
          accept={fileTypes}
          {...rest}
        />
      </InputParent>
    </InputBase>
  );
};

export const BasicPhoneInput = ({ label, icon, format, required, ...rest }) => {
  return (
    <PhoneInputBase>
      {label ? (
        <Label>{label} {required ? "*" : null}</Label>
      ) : null}
      <InputParent>
        {icon ? <i className="material-icons">{icon}</i> : null}
        <NumberFormat type="tel" format={format} {...rest} />
      </InputParent>
    </PhoneInputBase>
  );
};

export const BasicDatepicker = ({ label, icon, required, ...rest }) => {
  return (
    <InputBase>
      {label ? (
        <Label>
          {label} {required ? "*" : null}
        </Label>
      ) : null}
      <InputParent>
        {icon ? <i className="material-icons">{icon}</i> : null}
        <DateInput
          type="date"
          withIcon={!!icon}
          {...rest}
        />
      </InputParent>
    </InputBase>
  );
};
