import * as React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  z-index: 99;
`;

const ModalBody = styled.div`
  background: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 3em auto;
  width: calc(100% - 6em);
  max-height: calc(100% - 6em);
  max-width: ${props =>
    props.size === "medium"
      ? "768px"
      : props.size === "small"
      ? "460px"
      : "none"}
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5), -10px 0 8px -4px rgba(0, 0, 0, 0.25);
  z-index: 100;
  overflow-y: auto;
  @media (max-width: 640px) {
    margin: 1em auto;
    width: calc(100% - 2em);
    max-height: calc(100% - 2em);
  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: ${props => props.justifyContent || ""};
  align-items: center;
  background: #ff9671;
  font-weight: 500;
  line-height: normal;
  font-size: 1.125rem;
  text-transform: uppercase;
  color: #ffffff;
  padding: 1rem 0 1rem 2rem;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  > form {
    width: 100%;
  }
`;

const CloseBtn = styled.button`
  background: #ffa282;
  color: #ffffff;
  font-weight: 700;
  font-size: 2rem;
  border: 0;
  padding: 0.75rem;
  width: 3.5rem;
  height: 3.5rem;
  margin: -1rem 0;
  cursor: pointer;
`;

export const ModalItem = styled.div`
  display: flex;
  &:not(:first-of-type) {
    margin-top: 0.5rem;
  }
`;

export const Text = styled.p`
  line-height: normal;
  font-size: 0.875rem;
  color: #000000;
  margin: 0;
  &:not(:first-child) {
    margin-left: 0.5rem;
  }
`;

export const Link = styled.a`
  line-height: normal;
  font-size: 0.875rem;
  color: #000000;
  text-decoration: underline;
  margin: 0;
  &:not(:first-child) {
    margin-left: 0.5rem;
  }
`;

export const Title = styled(Text)`
  font-weight: 500;
  min-width: min-content;
`;

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement("div");
    this.el.style.display = "contents";
  }

  componentDidMount() {
    document.body.appendChild(this.el);
    document.body.style.overflow = "hidden";
  }

  componentWillUnmount() {
    document.body.removeChild(this.el);
    document.body.removeAttribute("style");
  }

  render() {
    const { id, size, title, closeModal } = this.props;
    return ReactDOM.createPortal(
      <React.Fragment>
        <Overlay onClick={closeModal} />
        <ModalBody size={size} id={id}>
          <ModalHeader justifyContent={!title ? "flex-end" : "space-between"}>
            {title}
            <CloseBtn onClick={closeModal}>
              <i className="material-icons">close</i>
            </CloseBtn>
          </ModalHeader>
          <ModalContent>{this.props.children}</ModalContent>
        </ModalBody>
      </React.Fragment>,
      this.el
    );
  }
}

export default Modal;
