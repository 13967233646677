import * as React from "react";
import {withNamespaces} from "react-i18next";
import Modal from "../../components/modal";
import {BasicInput, BasicPhoneInput, Label} from "../../components/basicInput";
import {Radio} from "../../components/radioButton";
import {FlexRow} from "../../components/flexboxes";
import {ActionButton} from "../../components/button";
import {bookCell} from "../../services/tracking.service";
import {handleError} from "../../services/other.service";
import {cellSizes} from "../../resources/constants/cell-sizes";
import { phoneFormats } from "../../resources/constants/phone-formats";


class BookCell extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            identifier: '',
            size: null,
            index: '',
            phone: '',
            email: '',
            fullname: '',
            address: '',
            price: ''
        };
    }

    validatePhone = () => {
        if ((/^((\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{8}$/.test(this.state.phone) && this.state.phone.length === 11 && this.state.phone[0] === '7') || this.state.phone === '') return true
        else return false;
    }

    book = e => {
        if(this.validatePhone()) {
            e.preventDefault();
            bookCell(this.state)
                .then(() => this.setState({identifier: ''}, () => this.props.close(true, true)))
                .catch(err => {
                    console.log(err); 
                    this.setState({}, () => handleError(err))});
        } else {
            e.preventDefault();
            let tmp = {
                response: {data: {message: {
                    ru: "Неверный формат номера телефона - должен состоять из 11 цифр c первой цифрой 7, либо укажите только email оставив поле телефона пустым",
                    en: "Invalid phone number format - must be 11 digits with 7 as the first digit",
                }}}
            }
            tmp.response.data.message = JSON.stringify(tmp.response.data.message);

            this.setState({}, () => handleError(tmp))
        }
    }

    handleChange = ({ target: { name, value } }) => {
        this.setState({ 
            [name]: name === "phone"
                ? value.substring(1, value.length).replace(/ +/g, "") //remove prefix and spaces between digits
                : value})
    }

    render() {
        const {t, close} = this.props;
        const {identifier, size, index, phone, email, fullname, address, price} = this.state;
        return (
            <Modal
                id="bookCell"
                size="small"
                title={t("title")}
                closeModal={() => close(false, false)}
            >
                <form onSubmit={this.book}>
                    <FlexRow marginBottom="1rem" fullWidth>
                        <BasicInput
                            label={t("index")}
                            name="index"
                            value={index}
                            type="text"
                            onChange={this.handleChange}
                            length="6"
                            required
                        />
                    </FlexRow>
                    <Label>{t("cellSize")} *</Label>
                    <FlexRow
                        alignItems="center"
                        innerSpace="0.25rem"
                        marginBottom="1rem"
                        wrapItems
                    >
                        {cellSizes.map((s, index) => (
                            <Radio
                                key={index}
                                id={s}
                                name={"size"}
                                title={t(`cellSizes:${s}`)}
                                value={s}
                                checked={size === s}
                                onChange={this.handleChange}
                            />
                        ))}
                    </FlexRow>
                    <FlexRow marginBottom="1rem" fullWidth>
                        <BasicInput
                            label={t("trackNumber")}
                            name="identifier"
                            value={identifier}
                            type="text"
                            onChange={this.handleChange}
                            required
                        />
                    </FlexRow>
                    <FlexRow marginBottom="1rem" fullWidth>
                        <BasicPhoneInput
                            label={t("phone")}
                            name="phone"
                            icon="phone_iphone"
                            value={phone}
                            onChange={this.handleChange}
                            format={phoneFormats["11"]}
                            required
                        />
                    </FlexRow>
                    <FlexRow marginBottom="1rem" fullWidth>
                        <BasicInput
                            label={t("email")}
                            name="email"
                            value={email}
                            type="email"
                            onChange={this.handleChange}
                            required
                        />
                    </FlexRow>
                    <FlexRow marginBottom="1rem" fullWidth>
                        <BasicInput
                            label={t("fullname")}
                            name="fullname"
                            value={fullname}
                            type="text"
                            onChange={this.handleChange}
                            required
                        />
                    </FlexRow>
                    <FlexRow marginBottom="1rem" fullWidth>
                        <BasicInput
                            label={t("address")}
                            name="address"
                            value={address}
                            type="text"
                            onChange={this.handleChange}
                        />
                    </FlexRow>
                    <FlexRow marginBottom="1rem" fullWidth>
                        <BasicInput
                            label={t("price")}
                            name="price"
                            value={price}
                            type="number"
                            onChange={this.handleChange}
                        />
                    </FlexRow>
                    <ActionButton
                        type="submit"
                        title={t("book")}
                        size="small"
                        click={this.book}
                        disabled={!identifier || !size || !index || (!phone && !email) || !fullname}
                    />
                </form>
            </Modal>
        );
    }
}

export default withNamespaces("bookCell")(BookCell);
