import * as React from "react";
import {withNamespaces} from "react-i18next";
import styled from 'styled-components';
import {Container} from "./container";

export const FooterBase = styled(Container)`
  padding: 1rem 2rem;
  background: #2C303B;
  margin-top: 0;
  line-height: normal;
  font-size: 0.875rem;
  text-align: left;
  color: #FFFFFF;
  min-height: 0;
  max-width: none;
  // @media (min-width: 1921px) {
  //   max-width: none;
  //   margin-left: 8rem;
  //   margin-right: 0;
  // }
`;

class Footer extends React.Component {
  render() {
    return <FooterBase>
      {this.props.t('copyright')}
    </FooterBase>;
  }
}

export default withNamespaces("global")(Footer);
