import * as React from "react";
import styled from "styled-components";

export const Th = styled.th`
  background-color: #eeeeee;
  text-align: ${props => props.textAlign || "center"};
  cursor: ${props => (props.clickable ? "pointer" : "default")};
`;

export const Td = styled.td`
  background-color: #FFFFFF;
  text-align: ${props => props.textAlign || "center"}
  vertical-align: ${props => props.verticalAlign || "inherit"}
  width: ${props =>
    props.size === "smallest" ? "5%" : props.size === "small" ? "10%" : ""};
`;

export const Tr = styled.tr`
  ${Th}, ${Td} {
    &:first-child {
      border-left: 0;
    }
    &:last-child {
      border-right: 0;
    }
  }
  &:first-child {
    ${Th} {
      border-top: 0;
    }
  }
  &:last-child {
    ${Td} {
      border-bottom: 0;
    }
  }
`;

export const TableBase = styled.table`
  width: ${props => (props.fullWidth ? "100%" : "auto")};
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.25);
  ${Th} {
    font-size: ${props => props.size === 'small' ? '0.875rem' : '1.125rem'};
  }
  ${Td} {
    font-size: ${props => props.size === 'small' ? '0.75rem' : '0.875rem'};
  }
  ${Th}, ${Td} {
    font-weight: normal;
    line-height: normal;
    color: #525252;
    padding: ${props =>
      props.size === "small" ? "0.5rem 1rem" : "0.75rem 1.5rem"};
    border: 2px solid #d6d7dc;
  }
`;

export const InformButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 0.875rem;
  text-align: center;
  background: #6b6f82;
  color: #ffffff;
  border-radius: 50%;
  border: 0;
  > i {
    font-size: 0.875rem;
  }
`;

const XScroll = styled.div`
  overflow-x: auto;
  border-radius: 5px;
  box-shadow: 0 1px 14px rgba(0, 0, 0, 0.25);
`;

export const Table = ({ fullWidth, size, headers, children, withoutHeader }) => {
  return (
    <XScroll>
      <TableBase fullWidth={fullWidth} size={size}>
        {
          headers ? (
            <>
              <thead>
                <Tr>{headers}</Tr>
              </thead>
              <tbody>{children}</tbody>
            </>
          ) :
          withoutHeader ? <tbody>{children}</tbody> :
            children
        }
      </TableBase>
    </XScroll>
  );
};
