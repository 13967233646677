import styled from "styled-components";

const ParagraphBase = styled.p`
  line-height: normal;
  color: #67676E;
  margin: 0;
  text-align: ${props => props.textAlign || ""};
`;

export const MenuFont = styled(ParagraphBase)`
  font-weight: 400;
  font-size: 0.875rem;
`;

export const MenuPageTitle = styled(ParagraphBase)`
  font-weight: 500;
  font-size: 1.125rem;
`;

export const CardTitle = styled.h3`
  line-height: normal;
  color: #67676E;
  margin: 0;
  font-weight: 500;
  font-size: 0.875rem;
`;

export const Subtitle = styled.h5`
  line-height: normal;
  color: #67676E;
  margin: 0 0 0.5rem 0;
  font-weight: 400;
  font-size: 0.875rem;
  text-transform: uppercase;
`;

export const Paragraph = styled(ParagraphBase)`
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 1.125rem;
  > a {
    color: #40a8e7;
  }
`;

export const SmallGray = styled(ParagraphBase)`
  font-size: 0.75rem;
`;