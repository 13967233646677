import styled from "styled-components";

export const DatePicker = styled.input.attrs(() => ({
  type: "date"
}))`
  appearance: none;
  -webkit-appearance: none;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 0.875rem;
  font-family: "Rubik", sans-serif;
  color: #969ec5;
  border: 0;
  border-bottom: 1px solid #969ec5;
  background: transparent;
  display: inline-block !important;
  visibility: visible !important;
  &::-webkit-clear-button {
    display: none;
  }
  &::-webkit-inner-spin-button {
    display: none;
  }
  &::-webkit-calendar-picker-indicator {
    color: #2c3e50;
  }
`;
