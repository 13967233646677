import * as React from "react";
import { withNamespaces } from "react-i18next";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import MainLayout from "../../layouts/main/index";
import AvailablePlans from "./availablePlans";
import BasicIntegration from "./basicIntegration";
import {getCurrentUser} from "../../services/authorization.service";

class Settings extends React.Component {
  componentDidMount() {
    if (!this.props.currentUserIsLoading)
      getCurrentUser();
  }
  render() {
    const { match } = this.props;
    return (
      <React.Fragment>
        {match.params.step === "available-plans" ? (
          <AvailablePlans />
        ) : match.params.step === "basic-integration" ? (
          <BasicIntegration />
        ) : (
          <Link to="/settings/available-plans">Available plans</Link>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({ currentUserIsLoading: state.currentUserIsLoading });

export default withNamespaces("settings")(MainLayout(connect(mapStateToProps)(Settings)));
