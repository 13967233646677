import * as React from "react";
import styled from "styled-components";

const RatingBase = styled.div`
  & input {
    display: none;
  }
`;

const StarLabel = styled.label`
  font-size: 1rem;
  color: ${props => props.active ? "#ff9671" : "#b8b8b8"};
  &:not(:last-of-type) {
    margin-right: 0.25rem;
  }
`;

const stars = [1, 2, 3, 4, 5];

const Star = ({ count, rating, change }) => {
  return (
    <React.Fragment>
      <input
        type="radio"
        name="rating"
        id={`star-${count}`}
        value={count}
        onChange={event => change ? change(event) : null}
      />
      <StarLabel active={rating >= count} htmlFor={`star-${count}`}>
        {rating >= count ? "★" : "☆"}
      </StarLabel>
    </React.Fragment>
  );
};

export const Rating = ({ rating, change }) => {
  return (
    <RatingBase>
      {stars.map(r => (
        <Star key={r} count={r} rating={rating} change={change} />
      ))}
    </RatingBase>
  );
};
