import * as React from "react";
import { withNamespaces } from "react-i18next";
import moment from "moment";
import DG from "2gis-maps";
import styled from "styled-components";
import { lockerStatusesImgs } from "../resources/constants/statuses";

const MapBlock = styled.div`
  border-radius: 5px;
  box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.25);
  overflow-x: hidden;
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: 30em;
  @media (max-width: 768px) {
    height: 25em;
  }
  & .gisMap {
    width: 100%;
    height: 100%;
  }
  & .map {
    width: 100%;
    height: 100%;
  }
`;

class MonitoringMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      map: null,
      markers: DG.featureGroup(),
      status: "",
      region: "01",
      city: "Астана",
      date: moment(),
      showOnMap: {
        ON: false,
        OFF: false,
        INACTIVE: false
      }
    };
  }

  componentWillMount() {
    this.setState({
      map: null
    });
  }

  componentDidMount() {
    this.props.onRef(this);
    this.loadMap(this.props.center);
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  loadMap = async center => {
    if (!this.state.map) {
      document.getElementById(
        "gisMap"
      ).innerHTML = await '<div id="map" class="map"></div>';
      this.setState(
        {
          map: DG.map("map", {
            center: center,
            zoom: 12
          })
        },
        () => {
          this.getLockers(true);
        }
      );
    }
  };

  setCenter = async () => {
    this.state.map.setView(this.props.center, 12);
  };

  getLockers = async fullReload => {
    await this.state.markers.removeFrom(this.state.map);
    this.setState(
      {
        date: fullReload ? moment() : this.state.date,
        markers: DG.featureGroup()
      },
      async () => {
        await this.props.lockers.forEach(l => this.addTastamatToMap(l.locker));
        this.state.markers.addTo(this.state.map);
      }
    );
  };

  addTastamatToMap = locker => {
    try {
      const statusIcon = DG.icon({
        iconUrl: lockerStatusesImgs[locker.status],
        iconSize: [24, 24]
      });
      DG.marker([locker.location.latitude, locker.location.longitude], {
        icon: statusIcon
      })
        .on("click", () => {
          this.props.getLocker(locker.id);
        })
        .addTo(this.state.markers)
        .bindPopup(locker.address.buildingShortRu);
    } catch (e) {}
  };

  render() {
    return (
      <MapBlock>
        <div id="gisMap" className="gisMap" />
      </MapBlock>
    );
  }
}

export default withNamespaces("monitoring")(MonitoringMap);
