import * as React from "react";
import styled from "styled-components";

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1rem;
  color: #67676e;
  position: relative;
  padding-left: 1.25rem;
  &:before {
    position: absolute;
    left: 0;
    top: calc(50% - 0.5em);
    content: "";
    display: block;
    width: 1em;
    height: 1em;
    border: 2px solid #f05a28;
    box-sizing: border-box;
    border-radius: 3px;
    background: #fff;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 0.25em;
    top: calc(50% - 0.25em);
    width: 0.45em;
    height: 0.1875em;
    border-left: 2px solid #f05a28;
    border-bottom: 2px solid #f05a28;
    transform: rotate(-45deg);
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
    opacity: 0;
  }
`;

const CheckboxBase = styled.div`
  display: flex;
  & input {
    display: none;
    &:checked {
      + ${CheckboxLabel} {
        &:after {
          opacity: 1;
        }
      }
    }
  }
`;

export const Checkbox = ({ ...props }) => {
  return (
    <CheckboxBase>
      <input type="checkbox" {...props} />
      <CheckboxLabel htmlFor={props.id}>{props.title}</CheckboxLabel>
    </CheckboxBase>
  );
};
