import React from "react";
import { withNamespaces } from "react-i18next";
import { capitalize } from "../../services/other.service";
import Modal from "../../components/modal";
import { Table, Td, Tr } from "../../components/flexibleTable";
import { LockerStatus } from "../lockers";

class ListByLockerAction extends React.Component {

  render() {
    const { t, i18n, lockers, action, close } = this.props;
    return (
      <Modal
        size="medium"
        title={t(`statuses:off.${action}`)}
        closeModal={close}
      >
        <Table fullWidth withoutHeader>
          {lockers.length > 0 ? (
            lockers.map((l, index) => (
              <Tr key={index}>
                <Td>
                  <LockerStatus status={l.locker.status}>
                    {l.locker.index}
                  </LockerStatus>
                </Td>
                <Td textAlign="left">
                  {
                    l.locker.address[
                      `buildingShort${capitalize(i18n.language)}`
                      ]
                  }
                  ,&nbsp;
                  {
                    l.locker.address[
                      `streetShort${capitalize(i18n.language)}`
                      ]
                  }
                  ,&nbsp;
                  {l.locker.address.buildingNumber}
                </Td>
                <Td>{l.booked}</Td>
                <Td>{l.dropped}</Td>
                <Td>{l.picked}</Td>
                <Td>{l.withdrawn}</Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={7}>{t("global:nothingFound")}</Td>
            </Tr>
          )}
        </Table>
      </Modal>
    )
  }
}

export default withNamespaces("lockers")(ListByLockerAction);
