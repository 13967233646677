import React from "react";
import { withNamespaces } from "react-i18next";
import styled from "styled-components";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import {
  lockerAction,
  getLockerRatings,
  sendLockerReview,
  getStatsByLockerId,
  getStatsLifecycle
} from "../../services/lockers.service";
import { capitalize, handleError } from "../../services/other.service";
import Modal, { ModalItem, Title, Text, Link } from "../../components/modal";
import { FlexColumn, FlexRow } from "../../components/flexboxes";
import { LabelWithBorder } from "../../components/labels";
import { Button } from "../../components/button";
import lockerImage from "../../resources/img/locker.svg";
import { Locker } from "../../components/locker";
import CellInfo from "../../components/cellInfo";
import { Review, WriteReview } from "../../components/review";
import { ActionButton } from "../../components/button";
import { boxInfo } from "../../services/boxes.service";
import moment from "moment";
import SortByPeriod from "../../components/sortByPeriod";
import BarChart from "../../components/barChart";
import LineChart from "../../components/lineChart";

const SelectAction = styled.select`
  max-width: none;
  > option {
    padding: 0.5rem 1rem;
  }
`;

const Charts = styled(FlexColumn)`
  min-height: 400px;
`;

class LockerInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      action: "nothing",
      tastamat: [],
      chosenCell: {},
      reviews: [],
      reviewsCount: null,
      comment: "",
      rating: 0,
      showReviewInput: false,
      page: 0,
      from: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      to: moment().set({ hour: 23, minute: 59, second: 59, millisecond: 999 }),
      chartType: "barChart"
    };
  }

  componentDidMount() {
    this.renderTastamat();
    this.getReviews();
    this.swapCharts(this.state.chartType);
  }

  getReviews = refresh => {
    getLockerRatings(this.props.locker.id, this.state.page)
      .then(res => {
        const reviews = !refresh ? this.state.reviews : res.data.list;
        if (!refresh) res.data.list.forEach(t => reviews.push(t));
        this.setState({
          reviews,
          reviewsCount: res.data.count
        });
      })
      .catch(err => handleError(err));
  };

  sendReview = () => {
    const { comment, rating } = this.state;
    sendLockerReview(this.props.locker.id, rating, comment)
      .then(() =>
        this.setState(
          {
            page: 0,
            comment: "",
            rating: "",
            showReviewInput: false
          },
          () => this.getReviews(true)
        )
      )
      .catch(err => handleError(err));
  };

  showMore = () => {
    this.setState(
      prevState => ({
        page: ++prevState.page
      }),
      () => this.getReviews()
    );
  };

  makeAction = (id, action) => {
    lockerAction(id, action)
      .then(() => {
        Swal.fire("", this.props.t(`alerts.${action}`), "success").then(() => {
          this.props.reload();
          this.setState({ action: "nothing" });
        });
      })
      .catch(err => this.setState({ action: "nothing" }));
  };

  showInfo() {
    const { i18n, t, locker } = this.props;
    const info = [
      {
        name: t("Name"),
        value: locker.address[`buildingShort${capitalize(i18n.language)}`]
      },
      {
        name: t("Country"),
        value: locker.address[`country${capitalize(i18n.language)}`]
      },
      {
        name: t("State"),
        value: locker.address[`state${capitalize(i18n.language)}`]
      },
      {
        name: t("City"),
        value: locker.address[`city${capitalize(i18n.language)}`]
      },
      {
        name: t("District"),
        value: locker.address[`district${capitalize(i18n.language)}`]
      },
      {
        name: t("Microdistrict"),
        value: locker.address[`microdistrict${capitalize(i18n.language)}`]
      },
      {
        name: t("Address"),
        value: `${locker.address[`street${capitalize(i18n.language)}`]}, ${
          locker.address.buildingNumber
        }`
      },
      { name: t("Working"), value: locker.location.time },
      {
        name: t("Internal"),
        value: locker.location[`description${capitalize(i18n.language)}`]
      },
      { name: t("2GIS"), value: locker.gisUrl }
    ];
    return info.map((i, index) =>
      i.value ? (
        <ModalItem key={index}>
          <Title>{i.name}</Title>
          {i.name === t("2GIS") ? (
            <Text
              as={Link}
              href={i.value}
              target="_blank"
              rel="noopener noreferrer"
            >
              {i.value}
            </Text>
          ) : (
            <Text>{i.value}</Text>
          )}
        </ModalItem>
      ) : null
    );
  }

  compare = (a, b, field) => {
    if (a[field] < b[field]) return -1;
    if (a[field] > b[field]) return 1;
    return 0;
  };

  renderTastamat = () => {
    const { locker } = this.props;
    const rows = locker.boxes.sort((a, b) => this.compare(a, b, "row"));
    const tastamat = [];

    for (let i = 0; i < rows.length / locker.columns; i++) {
      tastamat.push([]);
    }
    for (let i = 0; i < rows.length; i++) {
      if (rows[i] && tastamat[rows[i].row - 1]) {
        tastamat[rows[i].row - 1].push(rows[i])
      }
    }
    this.setState({
      tastamat: tastamat.map(row =>
        row.sort((a, b) => this.compare(a, b, "column"))
      )
    });
  };

  refreshTastamat = async () => {
    await this.props.reload();
    this.renderTastamat();
  };

  cellClick = async id => {
    boxInfo(id)
      .then(({ data }) => this.setState({ chosenCell: data }))
      .catch(err => handleError(err));
  };

  getStats = () => {
    const { from, to } = this.state;
    getStatsByLockerId(this.props.locker.id, from.valueOf(), to.valueOf())
      .then(async res => {
        let labels = [],
          series = [];
        await res.data.list.forEach(item => {
          labels.push(moment(item.interval.date).format("HH"));
          series.push((item.interval.offDuration / 60).toFixed(2));
        });
        this.setState({
          lineChart: {
            labels: [],
            series: []
          },
          barChart: {
            labels,
            series
          }
        });
      })
      .catch(err => handleError(err));
  };

  getStatsLifecycle = () => {
    const { from, to } = this.state;
    let arr = [],
      i,
      j;
    for (i = 0; i < 24; i++) {
      for (j = 0; j < 60; j++) {
        arr.push({
          on: true,
          date:
            (i === 0 ? "00" : i < 10 ? "0" + i : i) +
            ":" +
            (j === 0 ? "00" : j < 10 ? "0" + j : j)
        });
      }
    }
    getStatsLifecycle(this.props.locker.id, from.valueOf(), to.valueOf())
      .then(async res => {
        const list = res.data.list;
        let labels = [],
          series = [];
        if (list.length) {
          await list.forEach(async item => {
            item.date = await moment(item.date).format("HH:mm");
            await arr.push(item);
          });
          arr = await arr
            .filter(
              (item, index, self) =>
                index ===
                self.findIndex(t => t.on === item.on && t.date === item.date)
            )
            .sort((a, b) => {
              if (a.date < b.date) return -1;
              if (a.date > b.date) return 1;
              return 0;
            });
          const firstIndex = arr.findIndex(a => {
              return a.on === list[0].on && a.date === list[0].date;
            }),
            firstItem = arr.find(a => {
              return a.on === list[0].on && a.date === list[0].date;
            });
          const lastIndex = arr.findIndex(a => {
              return (
                a.on === list[list.length - 1].on &&
                a.date === list[list.length - 1].date
              );
            }),
            lastItem = arr.find(a => {
              return (
                a.on === list[list.length - 1].on &&
                a.date === list[list.length - 1].date
              );
            });
          for (let i = 0; i < firstIndex; i++) {
            arr[i].on = await !firstItem.on;
          }
          for (let i = lastIndex; i < arr.length; i++) {
            arr[i].on = await lastItem.on;
          }
          await arr.forEach(item => {
            labels.push(item.date);
            series.push(item.on ? 1 : 0);
          });
        }
        this.setState({
          barChart: {
            labels: [],
            series: []
          },
          lineChart: {
            labels,
            series
          }
        });
      })
      .catch(err => handleError(err));
  };

  getDataByPeriod = (from, to) => {
    this.setState(
      {
        from: from
          ? moment(from).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          : null,
        to: to
          ? moment(to).set({
              hour: 23,
              minute: 59,
              second: 59,
              millisecond: 999
            })
          : null
      },
      () => this.swapCharts(this.state.chartType)
    );
  };

  swapCharts = type => {
    this.setState(
      {
        chartType: type
      },
      () => {
        if (type === "lineChart") this.getStatsLifecycle();
        else if (type === "barChart") this.getStats();
      }
    );
  };

  render() {
    const { t, locker, role, close } = this.props;
    const {
      tastamat,
      chosenCell,
      comment,
      reviews,
      reviewsCount,
      showReviewInput,
      barChart,
      lineChart
    } = this.state;
    return locker ? (
      <Modal size="medium" title={locker.index} closeModal={close}>
        <FlexRow innerSpace="0.5em" wrapItems>
          {role === "ADMIN" || role === "SUPER_ADMIN" ? (
            <Locker
              locker={tastamat}
              chosenCell={chosenCell}
              cellClick={this.cellClick}
            />
          ) : (
            <img src={lockerImage} style={{ maxWidth: "100%" }} alt="" />
          )}
          <FlexColumn style={{ flex: 1 }}>
            {chosenCell.id ? (
              <CellInfo
                locker={locker}
                box={chosenCell}
                closeInfo={() => this.setState({ chosenCell: {} })}
                refresh={this.refreshTastamat}
                refreshBox={this.cellClick}
              />
            ) : (
              this.showInfo()
            )}
          </FlexColumn>
        </FlexRow>
        {role === "ADMIN" ? (
          <FlexColumn>
            <LabelWithBorder>{t("global:availabileActions")}</LabelWithBorder>
            <FlexRow justifyContent="flex-start" innerSpace="0.25rem" wrapItems>
              <Button
                as={SelectAction}
                primary
                small
                name="actions"
                value={this.state.action}
                onChange={e => this.makeAction(locker.id, e.target.value)}
              >
                <option value="nothing">{t("actions.title")}</option>
                {locker.book ? (
                  <option value="unbook">{t("actions.unbook")}</option>
                ) : (
                  <option value="book">{t("actions.book")}</option>
                )}
                {locker.drop ? (
                  <option value="undrop">{t("actions.undrop")}</option>
                ) : (
                  <option value="drop">{t("actions.drop")}</option>
                )}
                {locker.pick ? (
                  <option value="unpick">{t("actions.unpick")}</option>
                ) : (
                  <option value="pick">{t("actions.pick")}</option>
                )}
                {locker.status === 'INACTIVE' ? (
                    <option value="activate">{t("actions.activate")}</option>
                ) : (
                    <option value="deactivate">{t("actions.deactivate")}</option>
                )}
              </Button>
              {!showReviewInput && (
                <Button
                  onClick={() => this.setState({ showReviewInput: true })}
                  primary
                  small
                >
                  {t("actions.writeReview")}
                </Button>
              )}
              {/*<Button primary small disabled>{t("actions.viewHistory")}</Button>*/}
            </FlexRow>
            {showReviewInput && (
              <WriteReview
                rating={this.state.rating}
                setRating={e => this.setState({ rating: e.target.value })}
                value={comment}
                change={e => this.setState({ comment: e.target.value })}
                send={this.sendReview}
              />
            )}
          </FlexColumn>
        ) : null}
        {role === "ADMIN" || role === "SUPER_ADMIN"  && reviewsCount > 0 ? (
          <FlexColumn>
            <LabelWithBorder>
              {t("reviews")} ({reviewsCount})
            </LabelWithBorder>
            {reviews.map((r, index) => (
              <Review key={index} item={r} type="locker" />
            ))}
            {reviewsCount > reviews.length && (
              <ActionButton
                title={t("actions.showMore")}
                click={this.showMore}
                size={"small"}
              />
            )}
          </FlexColumn>
        ) : null}
        {role === "ADMIN" && (barChart || lineChart) ? (
          <Charts>
            <LabelWithBorder>{t("barChart")}</LabelWithBorder>
            <SortByPeriod
              parentCallback={this.getDataByPeriod}
              from={this.state.from}
              onlyDay
            />
            {lineChart && lineChart.series.length > 0 ? (
              <LineChart
                labels={lineChart.labels}
                series={lineChart.series}
                swapChart={this.swapCharts}
              />
            ) : (
              <BarChart
                labels={barChart.labels}
                series={barChart.series}
                swapChart={this.swapCharts}
              />
            )}
          </Charts>
        ) : null}
      </Modal>
    ) : null;
  }
}

const mapStateToProps = state => ({ role: state.currentUser.role });

export default withNamespaces("lockerInfo")(
  connect(mapStateToProps)(LockerInfo)
);
