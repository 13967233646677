import * as React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { withCookies } from "react-cookie";
import Modal from "../components/modal";
import { ActionButton } from "../components/button";
import { FlexRow } from "../components/flexboxes";
import {
  BasicInput,
  BasicPhoneInput,
  BasicSelect
} from "../components/basicInput";
import { phoneFormats } from "../resources/constants/phone-formats";
import { editUser } from "../services/staff.service";
import { getCurrentUser } from "../services/authorization.service";

class ProfileSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        firstname: "",
        lastname: "",
        birthDate: "",
        phone: "",
        email: ""
      },
      tableRowsPerPage: props.cookies.get("tableRowsPerPage") || 10,
      language: props.i18n.language
    };
  }

  componentDidMount() {
    if (!this.props.cookies.get("tableRowsPerPage"))
      this.props.cookies.set("tableRowsPerPage", 10, { path: "/" });

    this.setState({
      user: {
        ...this.state.user,
        ...this.props.currentUser
      }
    });
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      user: {
        ...this.state.user,
        [name]:
          name === "phone"
            ? value.substring(1, value.length).replace(/ +/g, "") //remove prefix and spaces between digits
            : name === "birthDate"
            ? new Date(value).getTime()
            : value
      }
    });
  };

  changeLanguage = event => {
    const { i18n, cookies } = this.props;
    this.setState({
      language: event.target.value
    });
    i18n.changeLanguage(event.target.value);
    cookies.set("i18lang", event.target.value, { path: "/" });
  };

  save = async user => {
    await editUser(user, this.props.close());
    getCurrentUser();
  };

  render() {
    const { t, close, cookies } = this.props;
    const { user, tableRowsPerPage, language } = this.state;
    return (
      <Modal size="small" title={t("title")} closeModal={close}>
        <form onSubmit={e => e.preventDefault()}>
          <BasicInput
            label={t("firstname")}
            type="text"
            icon="person"
            name="firstname"
            value={user.firstname}
            onChange={this.handleChange}
          />
          <BasicInput
            label={t("lastname")}
            type="text"
            icon="person"
            name="lastname"
            value={user.lastname}
            onChange={this.handleChange}
          />
          <BasicInput
            label={t("birthDate")}
            icon="date_range"
            name="birthDate"
            value={user.birthDate ? moment(user.birthDate).format('YYYY-MM-DD') : ''}
            type="date"
            onChange={this.handleChange}
          />
          <FlexRow innerSpace="1em">
            <BasicPhoneInput
              label={t("phone")}
              icon="phone_iphone"
              name="phone"
              value={user.phone}
              onChange={this.handleChange}
              format={phoneFormats["11"]}
            />
            <BasicInput
              label={t("email")}
              type="email"
              icon="email"
              name="email"
              value={user.email}
              onChange={this.handleChange}
            />
          </FlexRow>
          <FlexRow innerSpace="1em" marginBottom="0">
            <BasicSelect
              label={t("tableRowsPerPage")}
              icon="list"
              name={"tableRowsPerPage"}
              value={tableRowsPerPage}
              options={[5, 10, 30, 50, 100]}
              onChange={e => {
                cookies.set("tableRowsPerPage", e.target.value, { path: "/" });
                this.setState({ tableRowsPerPage: e.target.value });
              }}
            />
            <BasicSelect
              label={t("language.title")}
              icon="language"
              name={"language"}
              value={language}
              options={[
                { label: t("language.ru"), value: "ru" },
                { label: t("language.en"), value: "en" }
              ]}
              onChange={this.changeLanguage}
            />
          </FlexRow>
          { user.system && user.system.key ?
            <BasicInput
              name="key"
              type="text"
              value={user.system.key}
              icon="vpn_key"
              label={t("key")}
              canCopyToClipboard={true}
              disabled={true}
            /> : null
          }
          { user.system && user.system.token ?
            <BasicInput
              name="token"
              type="text"
              value={user.system.token}
              icon="security"
              label={t("token")}
              canCopyToClipboard={true}
              disabled={true}
            /> : null
          }
          <FlexRow justifyContent="flex-end" margin="2em 0 0">
            <ActionButton
              type="submit"
              size="small"
              title={t("save")}
              icon="save"
              click={() => this.save(user)}
            />
          </FlexRow>
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({ currentUser: state.currentUser });

export default withCookies(
  withNamespaces("profileSettings")(connect(mapStateToProps)(ProfileSettings))
);
