import ON from "../../resources/img/icons/ON.svg";
import OFF from "../../resources/img/icons/OFF.svg";
import INACTIVE from "../../resources/img/icons/INACTIVE.svg";
import PICK_OFF from "../../resources/img/icons/PICK_OFF.svg";
import BOOK_OFF from "../../resources/img/icons/BOOK_OFF.svg";
import DROP_OFF from "../../resources/img/icons/DROP_OFF.svg";

export const orderStatuses = [
  "ALL",
  "SENT",
  "END",
  "WITHDRAWN",
  "RESERVED",
  "UNRESERVED"
];

export const lockerStatuses = [
  "ALL",
  "ON",
  "OFF",
  "INACTIVE"
];

export const lockerStatusesImgs = {
  ON: ON,
  OFF: OFF,
  INACTIVE: INACTIVE,
  book: BOOK_OFF,
  drop: DROP_OFF,
  pick: PICK_OFF
};
